import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { Store } from 'src/app/models/store';
import { OrderService } from 'src/app/order.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-referral-promotion-value',
  templateUrl: './referral-promotion-value.component.html',
  styleUrls: ['./referral-promotion-value.component.sass']
})
export class ReferralPromotionValueComponent {
  store;
  offerCatalogSharableLink;
  storeId: any;
  isLoading = true;
  store_id: any;
  hash: any;
  phoneNumber: any;
  FilterPromot: any;
  form: FormGroup;
  default_msg: any;
  referralCode: any;
  errorMessage: string;
  idSet: any;
  submitted = false;
  referralForm!: FormGroup;
  constructor(
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    // private formBuilder: FormBuilder,
    public fb: FormBuilder,
    ngNavigatorShareService: NgNavigatorShareService,
  ) {
    this.ngNavigatorShareService = ngNavigatorShareService;
  }
  private ngNavigatorShareService: NgNavigatorShareService;

  ngOnInit(): void {
    this.storeId = this.route.snapshot.paramMap.get('storeId');
    this.hash = this.route.snapshot.paramMap.get('userHash');
    this.phoneNumber = localStorage.getItem('phoneNumber');
    this.store_id = localStorage.getItem('currentStore');
    this.FilterPromot = JSON.parse(localStorage.getItem("referee_gets"));
    this.orderService.getStoreInfo(this.storeId).subscribe(store => {
      this.store = Object.assign(new Store(), store);
      if (this.store.dynamic_offer_url) {
        this.offerCatalogSharableLink = this.store.dynamic_offer_url;
      } else {
        this.offerCatalogSharableLink = `${environment.hostName}/store/${this.store.url_slug}/offers/`
      }
      this.isLoading = false;
    });

    this.referralForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      phone: ['', [Validators.required, this.phoneLengthValidator(10, 15)]]
    });

  }

  phoneLengthValidator(minLength: number, maxLength: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value?.toString();
      if (!value) return null;
      if (value.length < minLength) {
        return { minlength: true };
      }
      if (value.length > maxLength) {
        return { maxlength: true };
      }
      return null;
    };
  }

  goBack() {
    window.history.back()
  }

  refereeRegistrationSubmit(): void {
    this.store_id = localStorage.getItem('currentStore')
    this.submitted = true;
    if (this.referralForm.invalid) {
      return;
    }
    let keySend = {
      "store_id": this.store_id,
      "referral_promotion_id": this.FilterPromot.id,
      "phone_number": '+1' + this.referralForm.value.phone,
      "referee_name": this.referralForm.value.name
    }
    this.orderService.refereeRegistration(keySend).subscribe(data => {
      this.default_msg = data.default_msg;
      if (data.is_verified == "true") {
        this.referralCode = data.referral_code;
        localStorage.setItem('phoneNumber', keySend.phone_number)
        // this.referralCodeSharableLink = `${location.host}/store/${this.store.url_slug}/${this.hash}/referral/${this.referralCode}/${this.promotionId}/${this.store_id}`;
        // this.referralLink(this.referralCodeSharableLink)
        this.router.navigate(['store/' + this.storeId + '/referral-promotion-code']);
      }
      else {
        this.refereeRegistrationOTP(data.data);
        this.phoneNumber = data.data.phone_number;
        localStorage.setItem('phone_num_val', this.phoneNumber)
        this.idSet = data.data.id;
        this.router.navigate(['store/' + this.storeId + '/referral-promotion-otp-verification']);
      }

    });
  }
  refereeRegistrationOTP(data) {
    this.store_id = localStorage.getItem('currentStore')
    this.idSet = data.id
    let keySend = {
      "store_id": this.store_id,
      "phone_number": data.phone_number,
      "referee_name": data.referee_name
    }
    this.orderService.refereeRegistrationOTP(this.idSet, keySend).subscribe(data => {
    });
  }
}
