import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from 'src/app/order.service';
import { Store } from 'src/app/models/store';

@Component({
  selector: 'app-offer-status',
  templateUrl: './offer-status.component.html',
  styleUrls: ['./offer-status.component.scss']
})
export class OfferStatusComponent implements OnInit {
  initial;
  storeId;
  offerOrderId;
  offersInCart;
  status;
  isLoading=false;
  displayedColumns: string[] = ['quantity', 'item', 'itemTotal'];
  store:Store;


  constructor(private route: ActivatedRoute,
    private orderService:OrderService,
    private router: Router) { }


  ngOnInit() {
    this.storeId = this.route.snapshot.paramMap.get('storeId');
    this.offerOrderId = this.route.snapshot.paramMap.get('offerOrderId');
    this.status = this.route.snapshot.paramMap.get('status');

    this.orderService.getOffer(this.storeId, this.offerOrderId).subscribe(offersInCart => {
      this.offersInCart = offersInCart;
      this.store = Object.assign(new Store(), offersInCart.store)
    });
  }

  goBack(){

    this.router.navigate(['store', this.storeId, 'offers', 'order',this.offerOrderId ]);

  }

}
