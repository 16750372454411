import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { OrderService } from 'src/app/order.service';
import { Store } from 'src/app/models/store';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-offer-detail',
  templateUrl: './offer-detail.component.html',
  styleUrls: ['./offer-detail.component.sass']
})
export class OfferDetailComponent implements OnInit {

  navigationSubscription;
  customPrice: any;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService,
    private formBuilder: FormBuilder,) {
    // this.navigationSubscription = this.router.events.subscribe((e: any) => {
    //   if (e instanceof NavigationEnd) {
    //     this.ngOnInit();
    //   }
    // });
  }

  storeId: string;
  offerId: string;
  offerOrderId: string;
  store: Store;
  offers: any[];
  selectedOffer: any;
  isLoading = true;
  ends;
  offerCatalogSharableLink;
  getOfferType: any;
  form: FormGroup
  cardType: any;

  ngOnInit() {

    this.form = this.formBuilder.group({
      price_value: ''
    })

    this.storeId = this.route.snapshot.paramMap.get('storeId');
    this.offerOrderId = this.route.snapshot.paramMap.get('offerOrderId');
    this.offerId = this.route.snapshot.paramMap.get('offerId');



    if (!this.offerOrderId) {
      this.orderService.createTempOfferOrder(this.storeId).subscribe(data => {
        this.offerOrderId = data['offer_order_id']
        this.router.navigate(['store', this.storeId, 'offers', 'order', this.offerOrderId, 'offer', this.offerId]);
      })
    } else {
      this.orderService.getStoreInfo(this.storeId).subscribe(store => {
        this.store = Object.assign(new Store(), store);
        this.offerCatalogSharableLink = `${environment.hostName}/store/${this.store.url_slug}/offers/${this.offerId}`
        this.orderService.getOfferDetails(this.storeId, this.offerOrderId, this.offerId).subscribe(offer => {
          this.selectedOffer = offer
          this.getOfferType = this.selectedOffer.offer_type;
          this.ends = moment(this.selectedOffer.ends).valueOf()
          this.isLoading = false;
        })
      });
    }
  }

  claimNow() {
    this.customPrice = this.form.value.price_value;
    if(this.customPrice != 0) {
      this.cardType = 1;
    } else {
      this.cardType = 0;
      this.customPrice = 0;
    }
    this.orderService.addOffer(this.storeId, this.offerOrderId, this.offerId, this.customPrice, this.cardType).subscribe(store => {
      this.router.navigate(['store', this.storeId, 'offers', 'order', this.offerOrderId])
    })
  }

  goBack() {
    this.router.navigate(['store', this.storeId, 'offers', 'order', this.offerOrderId, 'available'])
  }

}
