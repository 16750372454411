import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.sass']
})
export class ToolbarComponent implements OnInit {

  @Input() title;
  @Input() isLoading;
  @Input() hash;
  @Input() isStoreTitle
  @Output() onBack: EventEmitter<any> = new EventEmitter();

  showMenu;

  constructor() { }

  ngOnInit() {
    this.showMenu = true;
    // if(localStorage.getItem('phoneNumber') !== null){

    // }
  }

  back(){
    this.onBack.emit("1")
  }
}
