import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'store-bottom-sheet',
  templateUrl: './store-bottom-sheet.html',
  styleUrls: ['./store-bottom-sheet.scss']
})
export class StoreBottomSheetComponent {
  route:string;
  constructor(
    private bottomSheetRef: MatBottomSheetRef<StoreBottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private router:Router) {
      this.route = this.router.url.split("#")[0];
    }

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    // event.preventDefault();
  }
}

