import {Component, OnInit} from '@angular/core';
import {OrderService} from '../order.service';
import {UserActivityService} from '../_services/user-activity.service';
import {UserActivity} from '../models/user-activity';
import {ActivatedRoute, Router} from '@angular/router';
import {OrdersWithPayment} from '../models/order';
import {OrderHelpers} from '../order-summary/utils/helpers';

@Component({
  selector: 'app-order-suspicious',
  templateUrl: './order-suspicious.component.html',
  styleUrls: ['./order-suspicious.component.sass']
})
export class OrderSuspiciousComponent implements OnInit{

  userActivity: UserActivity[] = [];
  orderHash: string;
  order: OrdersWithPayment
  escalated: boolean = false;

  constructor(private userActivityService: UserActivityService,
              private orderService: OrderService,
              private route: ActivatedRoute,
              private router: Router) {}

  ngOnInit() {
    this.orderHash = this.route.snapshot.paramMap.get('o_hash');
    this.orderService.getOrder(this.orderHash).subscribe(order => {
      this.order = OrderHelpers.parseOrder(order);
    });
  }

  goBack(){
    window.history.back();
  }
}
