import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MenuItem } from 'src/app/models/order';

@Component({
  selector: 'menu-item-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss']
})
export class OptionsComponent implements OnInit {

  @Input() selectedMenuItem:MenuItem;
  @Input() menuGroupItems:MenuItem[];
  @Input() store;
  @Output() portionChange = new EventEmitter<MenuItem>()

  uiSetting:any;
  constructor() { }

  onPortionChange(event:any){
    this.selectedMenuItem = event.value;
    this.portionChange.emit(this.selectedMenuItem);
  }
  ngOnInit() {
    this.uiSetting = this.store.ui_settings;
  }

}
