import { Component } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-custom-tip-dialog',
  templateUrl: './custom-tip-dialog.component.html',
  styleUrls: ['./custom-tip-dialog.component.sass']
})
export class CustomTipDialogComponent {

  constructor(public dialogRef: MatDialogRef<CustomTipDialogComponent>,) { }

}
