import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { OrderService } from 'src/app/order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from 'src/app/models/store';
import { CardDetails } from 'src/app/models/order';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { PaymentMethodsBottomSheetComponent } from 'src/app/order-summary/payment-methods-bottom-sheet/payment-methods-bottom-sheet-component';
import { PaymentOptionService, PaymentOption } from 'src/app/_services/payment-option.service';
import { Constants } from 'src/app/constants';
import {WalletComponent} from '../../order-summary/wallet/wallet.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-offer-summary',
  templateUrl: './offer-summary.component.html',
  styleUrls: ['./offer-summary.component.scss']
})
export class OfferSummaryComponent implements OnInit {
  storeId;
  offerOrderId;
  offersInCart;
  store:Store;
  formQuantity = Array.from(Array(1).keys()).map(n => n+1);
  displayedColumns: string[] = ['quantity', 'item', 'itemTotal'];
  isLoading = true;
  phoneNumber
  otpSent
  otp
  preferredCard
  guestCardDetails:CardDetails[];
  paymentGateway;
  paymentOption:PaymentOption;

  constructor(
    private route: ActivatedRoute,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private orderService:OrderService,
    private paymentOptionService:PaymentOptionService,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.isLoading = true
    this.storeId = this.route.snapshot.paramMap.get('storeId');
    this.offerOrderId = this.route.snapshot.paramMap.get('offerOrderId');

    this.paymentOptionService.current.subscribe(paymentOption =>{
      if(paymentOption){
        this.paymentOption = paymentOption;
        this.ref.detectChanges();
        location.reload();
      }
    })

    this.orderService.getOffer(this.storeId, this.offerOrderId)
    .subscribe(offersInCart => {
      if(offersInCart.offer_order.is_submitted){
        this.router.navigate(['store', this.storeId,'offer', 'order', this.offerOrderId, 'status', 'success']);
      }
      this.offersInCart = offersInCart;
      this.paymentGateway = offersInCart.store.payment_gateway;

      if(this.offersInCart.offer_order.phone_number){
        this.phoneNumber = this.getFormattedPhoneNumber(this.offersInCart.offer_order.phone_number);
      }
      this.store = Object.assign(new Store(), offersInCart.store);
      if(this.offersInCart.selected_offers.length <= 0){
        this.router.navigate(['store', this.storeId, 'offers', 'order', this.offerOrderId, 'available'])
      }
      this.orderService.getPreferredPaymentForOffer(this.getStoreId(), this.offerOrderId, this.getPlainPhoneNumber(), this.paymentGateway).subscribe(data => {
        this.preferredCard = Object.assign(new CardDetails(), data['guest_payment_detail'])
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
        console.log(error)
      });

    })
  }

  getPlainPhoneNumber(){
    if(this.phoneNumber){
      let ph = this.phoneNumber.replace(/[\s()-]/g, '')
      return '+1' + ph.substring(0,10)
    }
    return "";
  }

  getPlainPhoneNumberFromString(phoneNumber:string){
    let ph = phoneNumber.replace(/[\s()-]/g, '')
    return '+1' + ph.substring(0,10)
  }

  getFormattedPhoneNumber(phoneNumber){
    return phoneNumber.replace(/^(\+1)(\d{0,3})(\d{0,3})(\d{0,4})/, '($2) $3-$4')
  }

  updateQuantity(item){
    if(item.quantity <= 0){
      this.deleteItem(item);
    }else{
      this.orderService.updateOfferQuantity(this.storeId, this.offerOrderId, item.id, item.quantity).subscribe(data => {
        this.ngOnInit();
      })
    }
  }

  deleteItem(item){
    this.orderService.deleteOffer(this.storeId, this.offerOrderId, item.id).subscribe(data =>{
      this.ngOnInit();
    })
  }

  onSelect(item){
    // parsed_item_id = item.id
    this.router.navigate(['store', this.storeId, 'offers', 'order', this.offerOrderId, 'offer', item.offer.id]);
  }

  goBack(){
    this.router.navigate(['store', this.storeId, 'offers', 'order', this.offerOrderId, 'available']);
  }

  sendOTP(){
    this.orderService.otpForOffer(this.storeId, this.offerOrderId, this.getPlainPhoneNumber()).subscribe(() => {
      this.otpSent = true;
    })
  }

  verifyOTP(){
    this.orderService.verifyOtpForOffer(this.storeId, this.offerOrderId, this.getPlainPhoneNumber(), this.otp).subscribe(data => {
      localStorage.setItem(Constants.PHONE_NUMBER_KEY, this.getPlainPhoneNumber())
      this.offersInCart.offer_order.phone_number_verified = data['is_verified']
      this.orderService.getLastPreferredPaymentForOffer(this.storeId, this.offerOrderId, this.getPlainPhoneNumber()).subscribe(data => {
        if(!data['is_cash']){
          this.preferredCard = Object.assign(new CardDetails(), data['guest_payment_detail'])
        }
      }, () => {
        this.preferredCard = null;
      })
    })
  }

  changePhone(){
    this.offersInCart.offer_order.phone_number_verified = false;
  }

  updatePhoneNumber(phoneNumber:string){
    if(this.getPlainPhoneNumberFromString(phoneNumber).length === 12){
      this.sendOTP()
    }
  }

  changeSelectedCard(){
    this.isLoading = true;
    this.orderService.getCards(this.store.id,
      this.getPlainPhoneNumber(), this.paymentGateway).subscribe(data => {
      this.guestCardDetails = data['cards'].map(o => Object.assign(new CardDetails(), o));
      this.isLoading = false;
    },
    (error) => {
      this.guestCardDetails = []
      this.isLoading = false;
    }).add(()=>{
      console.log(this.guestCardDetails);

      this.bottomSheet.open(PaymentMethodsBottomSheetComponent, {
        panelClass: 'payment-bottom-sheet-container',
        data:  {
          isOffer: true,
          orderHash: this.offerOrderId,
          phoneNumber: this.getPlainPhoneNumber(),
          orderTotal: this.offersInCart.payment.total,
          storeCredit: null,
          cardDetails: this.guestCardDetails,
          paymentGateway: this.paymentGateway,
          storeId: this.store.id,
          storeCreditEnabled: false,
          storeCashEnabled: false,
          storeCardEnabled: this.store.has_card_payment,
          surcharge: this.store.surcharge_percent,
          payrocUsername: this.store.payroc_username,
          store: this.store
        }
      }).afterDismissed().subscribe(paymentMethod => {
        console.log(paymentMethod);
        if(paymentMethod){
          this.preferredCard = paymentMethod.cardDetails
          this.paymentOptionService.updatePaymentOption(paymentMethod)
          this.ngOnInit()
        }
      });
    });
  }

  getStoreId(){
    return this.offersInCart.store.id;
  }

  getPaymentGateway(){
    console.log(this.offersInCart.store.payment_gateway);
  }

  // checkLastPreferredPayment(){
  //   if(this.getPlainPhoneNumber().length === 12){
  //     this.orderService.getPreferredPaymentForOffer(this.storeId,
  //       this.offerOrderId,
  //       this.getPlainPhoneNumber(),
  //       this.paymentGateway).subscribe(data => {
  //       if(!data['is_cash']){
  //         this.preferredCard = Object.assign(new CardDetails(), data['guest_payment_detail'])
  //       }
  //     }, () => {
  //       this.preferredCard = null;
  //     })
  //   }
  // }

  deleteCard(card:CardDetails){
    this.orderService.deleteCard(card).subscribe(() => {
      let index = this.guestCardDetails.indexOf(card, 1)
      if(index > -1){
        this.guestCardDetails.splice(index, 1)
      }
      // this.checkLastPreferredPayment()
    })
  }

  claimNow(){
    this.orderService.submitOfferOrder(this.getStoreId(), this.offerOrderId).subscribe(
      () => {
        this.router.navigate(['store', this.storeId,'offer', 'order', this.offerOrderId, 'status', 'success']);
      },
      () => {
        this.router.navigate(['store', this.storeId,'offer', 'order', this.offerOrderId, 'status', 'failure']);
      })
  }

  openWallet(){
    console.log("wallet")
    let dialogRef = this.dialog.open(WalletComponent, {
      disableClose: true,
      data:  {order: this.offersInCart, selectMode: true, forOffer: true},
      panelClass: ['full-screen-modal']
    });
    dialogRef.afterClosed().subscribe(data => {
      console.log(data);
      this.paymentOption = data;
      this.orderService.updatePreferredPaymentAsCardForOffer(this.getStoreId(), this.offerOrderId,
        data.cardDetail.id).subscribe(data => {
        this.paymentOptionService.updatePaymentOption(this.paymentOption)
          console.log(data);
      })
    })
  }
}
