import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  title: BehaviorSubject<string>;

  constructor() {
    this.title = new BehaviorSubject<string>(null);
  }

  setTitle(title:string){
    this.title.next(title)
  }
}
