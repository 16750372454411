import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { OrderService } from 'src/app/order.service';
import { OrdersWithPayment } from 'src/app/models/order';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.sass'],
  encapsulation : ViewEncapsulation.None,
})
export class FeedbackComponent implements OnInit {

  @Input() orderWithPayment:OrdersWithPayment;

  feedback;
  feedbackForm:FormGroup = this.getForm(null);
  rating:number = 0;
  geturl: string;

  constructor(private orderService:OrderService) { }

  getForm(data){
    return new FormGroup({
      feedback : new FormControl(data ? data.feedback : null)
    })
  }

  ngOnInit(): void {
    this.geturl = window.location.href;
    this.orderService.getOrderFeedback(this.orderWithPayment.bot_order.order_hash).subscribe(data => {
      this.feedback = data;
      this.feedbackForm = this.getForm(data);
      
    })
  }

  setStarRating(rating){
    this.rating = rating.rating;
  }

  submit(form){
    let payload = form.value;
    payload.bot_order_id = this.orderWithPayment.bot_order.id;
    payload.rating = this.rating;
    payload.order_url = this.geturl;
    this.orderService.sumitOrderFeedback(payload).subscribe(data => {
      this.feedback = data;
    })
  }
}
