import {
  Input,
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Output,
  EventEmitter,
  OnDestroy,
  HostListener,
  SimpleChanges,
  NgZone
} from '@angular/core';
import { OrdersWithPayment, Order, Modifier, BotOrder, CardDetails, ModifierOption } from 'src/app/models/order';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderService } from 'src/app/order.service';
import { Store } from 'src/app/models/store';
import { PaymentMethodsBottomSheetComponent } from '../payment-methods-bottom-sheet/payment-methods-bottom-sheet-component';
import * as moment from 'moment';
import { BehaviorSubject, forkJoin, Observable, Subject, Subscription, timer } from 'rxjs';
import { OrderHelpers } from '../utils/helpers';
import { MyErrorStateMatcher } from 'src/app/_helpers/error-matcher';
import { BooleanService, Flag } from 'src/app/_services/boolean.service';
import { PaymentOptionService, PaymentOption } from 'src/app/_services/payment-option.service';
import { OccasionDetailService } from 'src/app/_services/occasion-detail.service';
import { debounceTime, first, switchMap, takeUntil } from 'rxjs/operators';
import { ArrivedDialogComponent } from 'src/app/arrived-dialog/arrived-dialog.component';
import { Constants } from 'src/app/constants';
import { ComponentCanDeactivate } from 'src/app/_helpers/pending-changes-gaurd';
import {WalletComponent} from '../wallet/wallet.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PaymentGatewayService} from '../../_services/payment-gateway.service';
import {WorldnetDialogComponent} from '../../worldnet-dialog/worldnet-dialog.component';
import {OrderProcessingService} from '../../_services/order-processing.service';

@Component({
  selector: 'app-order-summary-master',
  templateUrl: './order-summary-master.component.html',
  styleUrls: ['./order-summary-master.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderSummaryMasterComponent implements OnInit, OnDestroy, ComponentCanDeactivate{

  @Input() store:Store;
  orderHash:string
  orderWithPayment:OrdersWithPayment;
  phoneNumber:string;
  isLoading = true
  orderInstruction:string;
  guestCardDetails:CardDetails[];
  // preferredPaymentCash:boolean;
  // preferredPaymentStoreCredit:boolean;
  // preferredCard:CardDetails;
  otpSent:boolean = false;
  otp:number;
  storeId:string;
  paymentGateway;
  storeCredit = 0;
  isSubmitted:boolean = false;
  orderName:string;
  optIn:boolean = true;
  position: string = "34px";
  errorMatcher = new MyErrorStateMatcher();
  openScheduleOnInit = false;

  tableSideTab = 'OPENTAB';
  paymentOption:PaymentOption;
  isAdmin:boolean;

  promoConditionsMet=true;
  sliderValue: number = 50;
  isSubmitting = false;
  uiSetting:any;
  //static message when store is closed
  displayStoreCloseMsg = `Can not submit order as your current time/timezone is outside of the merchant’s operating hours. Please review merchant’s local timezone operating hours and resubmit.`
  private paymentObs:Subscription;
  private occasionObs:Subscription;
  private orderSubmitSubject = new BehaviorSubject<string>('');
  private orderSubmitSub: Subscription;

  private inputRange: HTMLInputElement;
  private maxValue: number = 100;
  private speed: number = 12;
  private currValue: number;
  private rafID: number;
  inputID: any;
  private destroySubject: Subject<void> = new Subject();


  @HostListener('window:unload')
  unloadHandler(event) {
    localStorage.removeItem('menu_group_name');
    localStorage.removeItem('menu_sub_group_name');
  }
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return !this.isSubmitting;
  }

  constructor(
    private route: ActivatedRoute,
    private orderService:OrderService,
    private router: Router,
    private bottomSheet: MatBottomSheet,
    private snackBar: MatSnackBar,
    private boolService:BooleanService,
    private paymentOptionService: PaymentOptionService,
    private occasionDetailService: OccasionDetailService,
    private dialog: MatDialog,
    private paymentGatewayService: PaymentGatewayService,
    private ngZone: NgZone,
    private orderProcessingService: OrderProcessingService
  ) {}

  refresh(occasionDetail){
    console.log(occasionDetail);

    this.ngOnInit()
  }

  getStoreId(){
    return this.orderWithPayment.bot_order.store;
  }

  getPlainPhoneNumber(){
    if(this.phoneNumber){
      let ph = this.phoneNumber.replace(/[\s()-]/g, '')
      return '+1' + ph.substring(0,10)
    }
    return "";
  }

  getPlainPhoneNumberFromString(phoneNumber:string){
    let ph = phoneNumber.replace(/[\s()-]/g, '')
    return '+1' + ph.substring(0,10)
  }

  getFormattedPhoneNumber(phoneNumber){
    return phoneNumber.replace(/^(\+1)(\d{0,3})(\d{0,3})(\d{0,4})/, '($2) $3-$4')
  }


  refreshOrder(){
    this.isLoading = true
    this.orderService.getOrder(this.orderHash).subscribe(
      data => {
        this.orderWithPayment = Object.assign(new OrdersWithPayment(), data, {
          bot_order: Object.assign(new BotOrder(), data.bot_order, {
            occasion_schedule: moment(data.bot_order.occasion_schedule, 'hh:mm A MMM DD, YYYY').toDate()
          }),
          items: data.items.map(o => Object.assign(new Order(), o, {
            modifiers: o.modifiers.map(x  => Object.assign(new Modifier(), x, {
              modifier: Object.assign(new ModifierOption(), x.modifier)
            }))
          })),
          store: Object.assign(new Store(), data.store),
          payment: OrderHelpers.parsePayment(data.payment)
        });
        this.store = this.orderWithPayment.store;
        this.isLoading = false;
        this.populateOrderName()
    })
  }

  resetPayment(){
    this.paymentOptionService.current.subscribe(paymentOption => {
      if(paymentOption){
        this.paymentOption = paymentOption;
      }else{
        this.paymentOption = new PaymentOption(false, false, false, null);
      }
    })
  }


  ngOnInit() {

    this.isLoading = true
    this.orderHash = this.route.snapshot.paramMap.get('orderId');

    this.isAdmin = this.route.snapshot.queryParamMap.get("is_admin") === '1'

    this.paymentObs = this.paymentOptionService.current.subscribe(paymentOption =>{
      console.log(this.paymentOption, paymentOption);

      if(paymentOption){
        this.paymentOption = paymentOption;
        this.refreshOrder()
      }
    })

    this.occasionObs = this.occasionDetailService.current.subscribe(occasionDetail => {
      if(occasionDetail){
        console.log(occasionDetail);
        this.orderWithPayment && this.orderWithPayment.setOccasionDetail(occasionDetail);
        this.refreshOrder()
      }
    })

    this.orderService.getOrder(this.orderHash).subscribe(
      data => {
        this.orderWithPayment = Object.assign(new OrdersWithPayment(), data, {
          bot_order: Object.assign(new BotOrder(), data.bot_order, {
            occasion_schedule: moment(data.bot_order.occasion_schedule, 'hh:mm A MMM DD, YYYY').toDate()
          }),
          items: data.items.map(o => Object.assign(new Order(), o, {
            modifiers: o.modifiers.map(x  => Object.assign(new Modifier(), x))
          })),
          store: Object.assign(new Store(), data.store),
          payment: OrderHelpers.parsePayment(data.payment)
        });
        this.uiSetting = this.orderWithPayment.store.ui_settings;
        if(this.orderWithPayment.bot_order.is_expired){
          this.router.navigateByUrl(`/store/${this.orderWithPayment.store.url_slug}`);
        }else if(this.orderWithPayment.bot_order.is_submitted){
          this.router.navigate(['order', this.orderHash, 'status', 'success']);
        }else if(this.orderWithPayment.items.length === 0){
          this.router.navigate(['order', this.orderHash, 'store', this.orderWithPayment.store.url_slug])
        }

        this.store = this.orderWithPayment.store;
        this.paymentGateway = this.orderWithPayment.store.payment_gateway;
        this.storeId = this.orderWithPayment.store.id;

        if(!this.orderWithPayment.isTabOpen()){
          this.tableSideTab = 'CHECKOUT';
        }

        if(this.orderWithPayment.bot_order.phone_number){
          this.phoneNumber = this.getFormattedPhoneNumber(this.orderWithPayment.bot_order.phone_number);
        }

        let cachedInstruction = localStorage.getItem(this.orderHash+"order-instruction")
        if(cachedInstruction != null && this.orderWithPayment.bot_order.special_request != cachedInstruction){
          this.orderWithPayment.bot_order.special_request = cachedInstruction;
          this.orderInstruction = cachedInstruction
        }

        this.populateOrderName()

        // if(this.orderWithPayment.store.has_card_payment && this.orderWithPayment.payment.type!='CASH'){
        if(this.orderWithPayment.store.has_card_payment){
          this.orderService.getCards(this.storeId, this.getPlainPhoneNumber(), this.paymentGateway).subscribe(data => {
            this.storeCredit = data['store_credit']
          })

          this.orderService.getLastPreferredPayment(this.orderHash, this.getPlainPhoneNumber(), this.paymentGateway).subscribe((data:any) => {
            if(data['is_store_credit'] == true) {
              this.paymentOption = new PaymentOption(data['is_cash'], false, data['is_store_credit'], null, data['store_credit'])
              // console.log('222222222222 store', this.paymentOption);
              this.paymentOption.card = data.guest_payment_detail ? true :false
              this.paymentOption.cardDetail = data.guest_payment_detail ? data.guest_payment_detail :null
            }else if(data['guest_payment_detail'] && data['is_store_credit'] == false){
              let cardDetail = Object.assign(new CardDetails(), data['guest_payment_detail'])
              this.paymentOption = new PaymentOption(data['is_cash'], true, data['is_store_credit'], cardDetail, data['store_credit'])
              this.refreshOrder()
            }else{
              this.paymentOption = new PaymentOption(data['is_cash'], false, data['is_store_credit'], null, data['store_credit'])
            }

            console.log(this.paymentOption);

            this.paymentOptionService.updatePaymentOption(this.paymentOption)
            this.isLoading = false;
          },
          error => {
            if(this.orderWithPayment.bot_order.payment_type == 'STORE_CREDIT') {
              this.updatePaymentAsStoreCredit();
            } else {
              this.resetPayment()
            }
            this.isLoading = false;
          });

          /* IF STORE CREDIT IS CHOICE */
        } else if(this.orderWithPayment.bot_order.payment_type == 'STORE_CREDIT') {
          this.updatePaymentAsStoreCredit();
        } else{
          this.resetPayment();
          this.isLoading = false;
        }

        if(this.orderWithPayment.bot_order.occasion === 'DELIVERY'
        && this.orderWithPayment.store.delivery_minimum
        && (this.orderWithPayment.payment.sub_total + this.orderWithPayment.payment.store_credit) < this.orderWithPayment.store.delivery_minimum){
          this.snackBar.open(`Minimum order value for delivery is $${this.orderWithPayment.store.delivery_minimum}`, "OK", {
            verticalPosition: 'top'
          })
        }

        if(this.orderWithPayment.bot_order.phone_number_verified){
          if(this.orderWithPayment.bot_order.occasion === 'TABLESIDE'){
            if(!this.orderWithPayment.bot_order.dine_in_table_number){
              this.boolService.emitFlag(new Flag('openSchedule', true))
            }
          }

          if(this.orderWithPayment.bot_order.occasion === 'CURBSIDE'){
            if(!this.orderWithPayment.bot_order.curbside_vehicle_id){
              this.boolService.emitFlag(new Flag('openSchedule', true))
            }
          }

          if(this.orderWithPayment.bot_order.occasion === 'DINE-IN'){
            if(!this.orderWithPayment.bot_order.dine_in_table_number){
              this.boolService.emitFlag(new Flag('openSchedule', true))
            }
          }

          if(this.store.hasCurbsideOnly()
            && this.orderWithPayment.bot_order.occasion === 'PICKUP'
            && !this.orderWithPayment.store.has_order_occasion_pickup){
            this.orderWithPayment.bot_order.occasion = 'CURBSIDE';
            this.boolService.emitFlag(new Flag('openSchedule', true))
          }
        }
        if(this.orderWithPayment && this.orderWithPayment.store && !this.orderWithPayment.store.isStoreOpened()){
          this.snackBar.open(this.displayStoreCloseMsg, "OK", {
            duration: 10000,
            verticalPosition: 'top'
          })
        }
      },
      err => {console.log(err)});

  }

  updatePaymentAsStoreCredit() {
    this.orderService.getCards(this.storeId, this.getPlainPhoneNumber(), this.paymentGateway).subscribe((data:any) => {
    },(error) => {
      this.storeCredit = error.error.store_credit
      this.isLoading = false
    });
    this.paymentOption = new PaymentOption(false, false,true, null, this.storeCredit);
    this.paymentOptionService.updatePaymentOption(this.paymentOption);

    if (this.orderWithPayment.payment.total > 0)
    this.snackBar.open('Store credit value is not enough to cover the total order balance, Please select another payment option.', "OK", {
      verticalPosition: 'top'
    })
  }

  ngOnDestroy(){
    this.paymentObs.unsubscribe();
    this.occasionObs.unsubscribe();
    if (this.orderSubmitSub) {
      this.orderSubmitSub.unsubscribe();
    }

    this.destroySubject.next();
  }

  populateOrderName(){
    let cachedOrderName = localStorage.getItem(this.orderHash+"order-name")
    if(cachedOrderName != null && this.orderWithPayment.bot_order.order_name != cachedOrderName){
      this.orderWithPayment.bot_order.order_name = cachedOrderName;
      this.orderName = cachedOrderName
    }else{
      this.orderWithPayment.bot_order.order_name = localStorage.getItem("orderName");
      this.orderName = localStorage.getItem("orderName");
    }
  }

  checkLastPreferredPayment(){
    if(this.getPlainPhoneNumber().length === 12){
      this.orderService.getLastPreferredPayment(this.orderHash, this.getPlainPhoneNumber(), this.paymentGateway).subscribe(data => {
        if(!data['is_cash'] || !data['is_store_credit']){
          let cardDetail = Object.assign(new CardDetails(), data['guest_payment_detail'])
          this.paymentOption = new PaymentOption(false, true, false, cardDetail, data['store_credit'])
          this.paymentOptionService.updatePaymentOption(this.paymentOption)
          this.refreshOrder()
        }
      }, () => {
        this.paymentOption = new PaymentOption(false, false, false, null, 0)
      })
    }
  }

  updatePhoneNumber(phoneNumber:string){
    if(this.getPlainPhoneNumberFromString(phoneNumber).length === 12){
      let plainPhoneNumber = this.getPlainPhoneNumberFromString(phoneNumber);
      const phone_number_check = plainPhoneNumber.replace(/\+/g, '');
      this.orderService.checkNumberBlocked(this.storeId, phone_number_check).subscribe((data:any) => {
        if (data.data == false) {
          this.orderService.updatePhoneNumber(this.orderHash, plainPhoneNumber).subscribe(() => {
            this.orderService.otp(this.orderHash, plainPhoneNumber).subscribe(() => {
              this.otpSent = true;
            })
          })
        } else {
          this.snackBar.open('Your phone number is blocked', "OK", {
            verticalPosition: 'top'
          })
        }
      });

    }
  }

  goBack(){
    this.router.navigate(['order', this.orderHash, 'store', this.orderWithPayment.store.url_slug])
  }

  onSubmit(){
    if (this.isSubmitting) return;

    if(this.paymentOption.mobilePaymentService){
      console.log("test")
      this.paymentGatewayService.getHPPUrlGA(+this.store.id, `${this.orderWithPayment.bot_order.id}`, this.orderWithPayment.payment.total).subscribe((data:any) => {

        const form = document.createElement('form');
        form.method = 'POST';
        form.action = "https://payments.worldnettps.com/merchant/paymentpage";

        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = key;
            hiddenField.value = data[key];
            form.appendChild(hiddenField);
          }
        }
        document.body.appendChild(form);
        form.submit();
      });
    }
    else if(this.orderWithPayment.bot_order.occasion === 'DELIVERY'
        && this.orderWithPayment.store.delivery_minimum
        && (this.orderWithPayment.payment.sub_total + this.orderWithPayment.payment.store_credit) < this.orderWithPayment.store.delivery_minimum){
          this.snackBar.open(`Minimum order value for delivery is $${this.orderWithPayment.store.delivery_minimum}`, "OK", {
            verticalPosition: 'top'
          })
          this.inputRange.value = '0';
    } else {
      this.isSubmitting = true;

      var obs = null
      if (this.orderWithPayment.bot_order.occasion === 'TABLESIDE'
        || (this.store.enable_pos_pay_later && this.paymentOption.cash)){
        const payload = {
          'cash_total': this.orderWithPayment.payment.cash_due_total,
        }
        obs = this.orderService.payLater(this.orderHash, payload)
      } else {
        obs = this.orderService.submitOrder(this.orderHash, this.optIn).pipe(takeUntil(this.destroySubject))
      }
      this.orderService
        .updateOrderInstructions(this.orderHash, this.orderInstruction, this.orderName).pipe(takeUntil(this.destroySubject))
        .subscribe(r => {
          this.orderSubmitSubject.next('');
          if (this.orderSubmitSub) {
            this.orderSubmitSub.unsubscribe();
          }
          this.orderService
            .updateOrderInstructions(this.orderHash, this.orderInstruction, this.orderName)
            .subscribe(r => {
              this.orderSubmitSubject.next('');
              if (this.orderSubmitSub) {
                this.orderSubmitSub.unsubscribe();
              }
              this.orderSubmitSub = this.orderSubmitSubject.pipe(debounceTime(500)).subscribe((debouncedFilter) => {
                obs.subscribe(
                  (success) => {
                    localStorage.removeItem('menu_group_name');
                    localStorage.removeItem('menu_sub_group_name');
                    this.isSubmitting = false;
                    this.router.navigate(['order', this.orderHash, 'status', 'success']);
                  },
                  (error) => {
                    console.log(error)
                    this.isSubmitting = false;
              if(error.status === 403){
                this.router.navigate(['order', this.orderHash, 'status', 'suspicious']);
              }else{
                this.router.navigate(['order', this.orderHash, 'status', 'failure']);
              }
            })
          });
        })
      });
    }
  }

  instructionChange(inst:string){
    localStorage.setItem(this.orderHash+"order-instruction", inst)
    this.orderInstruction = inst;
  }

  orderNameChange(name:string){
    localStorage.setItem(this.orderHash+"order-name", name)
    this.orderName = name;
  }

  addCard(){
    this.isLoading = true;
    this.guestCardDetails = [];
    this.orderService.getCards(this.storeId, this.getPlainPhoneNumber(), this.paymentGateway).subscribe(data => {
      this.guestCardDetails = data['cards'].map(o => Object.assign(new CardDetails(), o));
      this.storeCredit = data['store_credit']
      this.isLoading = false;
    },
    (error) => {
      this.guestCardDetails = []
      this.storeCredit = error.error.store_credit
      this.isLoading = false
    }).add(()=>{
      this.bottomSheet.open(PaymentMethodsBottomSheetComponent, {
        panelClass: 'payment-bottom-sheet-container',
        disableClose: !this.paymentOption.hasPaymentOption(),
        data:  {
          orderHash: this.orderWithPayment.bot_order.order_hash,
          phoneNumber: this.orderWithPayment.bot_order.phone_number,
          orderTotal: this.orderWithPayment.getOriginalTotal(),
          storeCredit: this.storeCredit,
          cardDetails: this.guestCardDetails,
          paymentGateway: this.orderWithPayment.store.payment_gateway,
          storeId: this.orderWithPayment.store.id,
          parent: this,
          storeCreditEnabled: true,
          storeCashEnabled: this.store.enable_cash_payment,
          storeCardEnabled: this.store.has_card_payment,
          paymentOption: this.paymentOption,
          surcharge: this.store.surcharge_percent,
          payrocUsername: this.store.payroc_username,
          nmiPublicKey: this.store.nmi_public_key,
          store: this.store
        }
      }).afterDismissed().subscribe(data => {
        if(data){
          this.paymentOption = data.paymentOption
          this.paymentOptionService.updatePaymentOption(data.paymentOption)
          this.isLoading = false;
        }
      });
    });
  }

  sendOTP(){

    const plainNumber = this.getPlainPhoneNumber();
    const phone_number_check = plainNumber.replace(/\+/g, '');
    this.orderService.checkNumberBlocked(this.storeId, phone_number_check).subscribe((data:any) => {
      if (data.data == false) {
        this.orderService.otp(this.orderHash, this.getPlainPhoneNumber()).subscribe(data => {
          this.otpSent = true;
        })
      } else {
        this.snackBar.open('Your phone number is blocked', "OK", {
          verticalPosition: 'top'
        })
      }
    });

  }

  verifyOTP(){
    const plainNumber = this.getPlainPhoneNumber();
    const phone_number_check = plainNumber.replace(/\+/g, '');
    this.orderService.checkNumberBlocked(this.storeId, phone_number_check).subscribe((data:any) => {
      if (data.data == false) {
        this.orderService.verifyOtp(this.orderHash, this.getPlainPhoneNumber(), this.otp).subscribe(data => {
          localStorage.setItem(Constants.PHONE_NUMBER_KEY, this.getPlainPhoneNumber())
          this.orderWithPayment.bot_order.phone_number_verified = data['is_verified'];
          this.orderWithPayment.bot_order.phone_number = this.getPlainPhoneNumber();
          this.orderService
            .getLastPreferredPayment(this.orderHash,
              this.getPlainPhoneNumber(),
              this.paymentGateway).subscribe(data => {
                this.isLoading = false;
              const cardDetail = Object.assign(new CardDetails(), data['guest_payment_detail'])
              this.paymentOption = new PaymentOption(data['is_cash'], true, data['is_store_credit'], cardDetail, data['store_credit'])
              this.paymentOptionService.updatePaymentOption(this.paymentOption)
              this.boolService.emitFlag(new Flag("openOccasion", true))
          }, error=> {
            this.paymentOption = new PaymentOption(false, false, false, null)
            this.paymentOptionService.updatePaymentOption(this.paymentOption)
          })
          this.refreshOrder()
        })
      } else {
        this.snackBar.open('Your phone number is blocked', "OK", {
          verticalPosition: 'top'
        })
      }
    });
  }

  changePhone(){
    this.orderWithPayment.bot_order.phone_number_verified = false;
  }

  deleteCard(card:CardDetails){
    this.orderService.deleteCard(card).subscribe(() => {
      let index = this.guestCardDetails.indexOf(card, 1)
      if(index > -1){
        this.guestCardDetails.splice(index, 1)
      }
      this.checkLastPreferredPayment()
    })
  }

  isValidOccasion(occasion){
    let closingTime = this.store.getClosingHours();
    let buffer = +this.store.last_order_buffer[occasion];
    if(buffer > 0){
      let updated = moment(closingTime).subtract(buffer, 'minutes').toDate();
      return moment().toDate() < updated;
    }else{
      return true;
    }
  }

  setPromoCodeValidation(event){
    console.log(event);
    this.promoConditionsMet = event;
  }

  isValid(keepPaymentCheck = false){
    let phoneCheck = this.getPlainPhoneNumber() != "" && this.getPlainPhoneNumber().length === 12 && this.orderWithPayment.bot_order.phone_number_verified
    let paymentCheck = this.paymentOption && (this.paymentOption.storeCredit || this.paymentOption.card || this.paymentOption.cash) || this.paymentOption.mobilePaymentService;
    //override paymentCheck if we don't need payment method to be selectes(show_menu_price is N/A)

    if(keepPaymentCheck){
      paymentCheck = true;
    }
    let validOccasion = this.orderWithPayment.bot_order.occasion && this.isValidOccasion(this.orderWithPayment.bot_order.occasion)
    let validAvailability;
    if (this.orderWithPayment.items.length > 0) {
      validAvailability = this.orderWithPayment.items.map(i => {
        if(i.menu_item.available_qty !== null){
          return i.menu_item.available_qty > 0;
        }else return true;
      }).reduce((l,r) => l && r)
    }

    /* CHECK STORE CREDIT IS SUFFICIENT OR NOT */
    let validStoreCredit = this.orderWithPayment.bot_order.payment_type == 'STORE_CREDIT' && this.orderWithPayment.payment.total > 0;

    return !this.isSubmitting && phoneCheck && paymentCheck && this.orderWithPayment.isValidOrder() && this.orderName && validOccasion && this.promoConditionsMet && validAvailability && !validStoreCredit;
  }

  hasItemToSubmit(){
    return this.orderWithPayment.items.filter(i => !i.ts_submitted).length > 0
      || this.orderWithPayment.misc_items.filter(i => !i.ts_submitted).length > 0
  }

  hasPlacedTablesideOrder(){
    return this.orderWithPayment.items.filter(i => i.ts_submitted).length > 0
  }

  submitTablesideOpenTab(){
    this.isLoading = true;
    console.log("submit open tab");
    let oi = this.orderService.updateOrderInstructions(this.orderHash, this.orderInstruction, this.orderName)
    oi.subscribe(data => {
      this.orderService.submitTablesideOpenTab(this.orderHash).subscribe(data => {
        console.log(data);
        this.ngOnInit()
      }, error =>{
        this.router.navigate(['order', this.orderHash, 'status', 'failure']);
      })
    })
  }

  closeTableSide(){
    const dialogRef = this.dialog.open(ArrivedDialogComponent, {
      width: '350px',
      disableClose: true,
      data:{
        confirmText: 'Yes',
        message: 'Ready to close your tab?',
        parking:0,
        spot_field:false
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if(data){
        this.checkoutTableSide();
      }else{
        this.tableSideTab = 'OPENTAB';
      }
    });
  }

  checkoutTableSide(){
    let oi = this.orderService.updateOrderInstructions(this.orderHash, this.orderInstruction, this.orderName)
    oi.subscribe(data => {
      this.orderService.submitTablesideConfirm(this.orderHash, this.optIn).subscribe(
        () => {
          this.router.navigate(['order', this.orderHash, 'status', 'success']);
        },
        () => {
          this.router.navigate(['order', this.orderHash, 'status', 'failure']);
        })
    })
  }

  /* SLIDE TO SUBMIT FEATURE */
  moveDot(changes: SimpleChanges, value:any): void{
    this.inputRange = document.getElementsByClassName('pullee')[0] as HTMLInputElement;
    this.inputRange.min = '0';
    this.inputRange.max = this.maxValue.toString();
    this.inputRange.addEventListener('mousedown', this.unlockStartHandler.bind(this), false);
    this.inputRange.addEventListener('mouseup', this.unlockEndHandler.bind(this), false);
    this.inputRange.addEventListener('touchend', this.unlockEndHandler.bind(this), false);
    this.inputID = value;
  }

  unlockStartHandler() {
    window.cancelAnimationFrame(this.rafID);
    this.currValue = +this.inputRange.value;
  }

  unlockEndHandler() {
    this.currValue = +this.inputRange.value;
    if (this.currValue >= this.maxValue) {
      this.successHandler();

    } else {
      this.rafID = window.requestAnimationFrame(this.animateHandler.bind(this));
    }
  }

  animateHandler() {
    this.inputRange.value = this.currValue.toString();
    if (this.currValue > -1) {
      window.requestAnimationFrame(this.animateHandler.bind(this));
    }
    this.currValue = this.currValue - this.speed;
  }

  successHandler() {
    if (this.inputID == 'cash_card') {
      console.log('CASH OR CARD ON SUBMIT');
      this.onSubmit()
    } else if (this.inputID == 'table_open') {
      console.log('Table OPEN');
      this.submitTablesideOpenTab();
    } else if (this.inputID =='table_checkout') {
      console.log('Table CHECKOUT');
      this.checkoutTableSide();
    } else {
      console.log('NOTHING');

    }
    // alert('Success')
    this.inputRange.value = '0';
  }

  openWallet(){
    let defaultPaymentOption = new PaymentOption(false, false, false, null, 0);
    let dialogRef = null;
    this.orderService.updatePreferredPayment(this.orderWithPayment.bot_order.order_hash, defaultPaymentOption).subscribe(data => {
      this.orderService.getOrder(this.orderHash).subscribe(data => {
        this.orderWithPayment = OrderHelpers.parseOrder(data);
        dialogRef = this.dialog.open(WalletComponent, {
          disableClose: true,
          data:  {order: this.orderWithPayment, selectMode: true},
          panelClass: ['full-screen-modal']
        });
        dialogRef.afterClosed().subscribe(data => {
          console.log(data);
          this.paymentOption = data;
          // this.paymentOptionService.updatePaymentOption(data)
          let payload = {
            is_cash: data.cash, is_card: data.card, is_store_credit: data.storeCredit,
            guest_payment_id: data.cardDetail ? data.cardDetail.id : null
          }
          this.orderService.updatePreferredPayment(this.orderHash, payload).subscribe(data => {
            this.refreshOrder();
          });
        });
      });


    });
  }

  deliveryCondition(orderWithPayment) {
    const { bot_order, payment, store } = orderWithPayment; // Destructuring for easier access to properties
    const isDeliveryOrder = bot_order?.occasion === 'DELIVERY';
    const hasDeliveryMinimum = store.delivery_minimum;
    const totalPayment = payment.sub_total + payment.store_credit;
    const meetsDeliveryMinimum = totalPayment < store.delivery_minimum;

    // Directly return the evaluation result of all conditions
    return isDeliveryOrder && hasDeliveryMinimum && meetsDeliveryMinimum;
  }

  getPaymentOptionType(paymentOption: any): string {
    if (paymentOption?.storeCredit && !paymentOption?.cash && !paymentOption?.cardDetail) {
      return 'storeCredit';
    }
    if (paymentOption?.cash && !paymentOption?.cardDetail) {
      return 'cash';
    }
    if (paymentOption?.cardDetail && paymentOption?.cardDetail.token) {
      return 'card';
    }
    if(paymentOption?.mobilePaymentService){
      return 'mobilePaymentService';
    }
    return 'default';
  }

}
