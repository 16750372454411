import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from 'src/app/order.service';
import { Store } from 'src/app/models/store';
import { RedeemConfirmationDialogComponent } from './redeem-confirmation-dialog/redeem-confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-redeem',
  templateUrl: './redeem.component.html',
  styleUrls: ['./redeem.component.sass']
})
export class RedeemComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private orderService: OrderService,
    private router: Router,
    private dialog: MatDialog) { }

  offerId;
  offerItem;
  store;
  redeemAmount;
  isLoading = true;
  hash
  balance
  useNow:boolean=false;
  orderHash: any;
  storeOffer: any;
  uiUx: boolean;
  storeId: any;

  ngOnInit() {
    this.hash = this.route.snapshot.paramMap.get('hash');
    this.offerId = this.route.snapshot.paramMap.get('offerId');
    this.storeId = localStorage.getItem('currentStore');

    this.orderService.getOfferItemWithHash(this.hash, this.offerId).subscribe(offer => {
      console.log('11111111111111111111111111', offer);

      this.offerItem = offer;
      // if (this.offerItem.offer != null) {
      //   this.storeOffer = this.offerItem.offer.store;
      // } else {
      //   this.storeOffer = this.offerItem.offer_order.store;
      // }
      this.orderService.getStoreInfo(this.storeId).subscribe(store => {
        this.store = Object.assign(new Store(), store);
        this.uiUx = this.store.feature_flag.stand_alone_gift;

      });
      this.balance = offer.total_list_price - offer.redeemed_amount;
      this.redeemAmount = 0.00;
      this.isLoading = false;
    })
  }


  decrement() {
    if (this.redeemAmount > 0 && (this.redeemAmount - 5) > 0) {
      this.redeemAmount = this.redeemAmount - 5;
    }
  }

  increment() {
    if (this.redeemAmount < this.balance && this.balance > 0) {
      this.redeemAmount = this.redeemAmount + 5;
    }
  }

  redeem(offer) {

    const dialogRef = this.dialog.open(RedeemConfirmationDialogComponent, {
      disableClose: true,
      data: {
        amount: this.redeemAmount
      }
    });

    dialogRef.afterClosed().subscribe(r => {
      if (r == 1) {
        this.isLoading = true;
        this.orderService.redeemOfferItem(this.hash, this.offerId, this.redeemAmount, this.storeId).subscribe(redeemHistory => {
          this.router.navigate([this.hash, 'my-offers', this.offerId, 'redeem', redeemHistory['id'], 'status'])
        })
      }
    });
  }

  goBack() {
    window.history.back()
  }

  orderNow() {
    this.isLoading = true;
    this.orderService.createTempOrderWithPhone(this.store.url_slug, this.offerItem.offer_order.phone_number).subscribe(response => {
      this.router.navigate(['order', response['order_hash'], 'store', response['url_slug']])
      this.orderHash = response['order_hash'];
      this.useNowGift(this.orderHash);
    })
  }

  useNowGift(e) {
    this.orderService.useGiftOrder(e).subscribe(res => {
      // console.log('33333333333333', res);
    });
  }


  scrollTo(el:HTMLElement){
    el.scrollIntoView()
  }

}
