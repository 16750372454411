import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderService } from 'src/app/order.service';
import { Store } from 'src/app/models/store';
import { LoyaltyPointService } from 'src/app/_services/loyalty-point.service';


@Component({
  selector: 'app-redeem-history',
  templateUrl: './redeem-history.component.html',
  styleUrls: ['./redeem-history.component.sass']
})
export class RedeemHistoryComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private orderService: OrderService,
    private router: Router,
    private loyaltyPointService: LoyaltyPointService) { }

  history;
  isLoading = true;
  hash
  storeId;
  store;
  storeid: any;
  pointHistory;

  ngOnInit() {
    this.hash = this.route.snapshot.paramMap.get('hash');
    this.storeId = this.route.snapshot.paramMap.get('storeId');
    this.storeid = localStorage.getItem('currentStore');
    
    this.orderService.getStoreInfo(this.storeId).subscribe(store => this.store = Object.assign(new Store(), store))
    this.orderService.redeemHistoryAll(this.hash).subscribe(data => {
      this.history = data;
      this.isLoading = false;
    })
    this.pointRedeemHistory();
  }

  goTo(item) {
    this.router.navigate([this.hash, 'my-offers', item.offer_item.id, 'redeem', item.id, 'status'])
  }

  goBack() {
    window.history.back()
  }

  pointRedeemHistory() {
    this.loyaltyPointService.getPointRedeemHistory(this.storeid,this.hash).subscribe((data: any) => {
      this.pointHistory = data.data;

    });
  }
}
