import { Component, OnInit } from '@angular/core';
import { MenuItem, ModifierOption, ModifierGroups, EditModifierOptions, ModifierGroup, Modifier, OrdersWithPayment } from '../../models/order';
import { OrderService } from 'src/app/order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from 'src/app/models/store';
import { StoreService } from 'src/app/_services/store.service';
import { QuantityChange } from '../quantity-control/quantity-control.component';

@Component({
  selector: 'app-menu-item-master',
  templateUrl: './menu-item-master.component.html',
  styleUrls: ['./menu-item-master.component.scss']
})
export class MenuItemMasterComponent implements OnInit {

  title:string;
  description:string;
  selectedMenuItem:MenuItem;
  menuGroupItems:MenuItem[];
  storeId:string;
  menuItemGroupId:string;
  menuItemId:string;
  parsedMenuItemId:string;
  modifierGroups:ModifierGroup[];
  quantity:number;
  specialRequest:string;
  orderId:string;
  selectedModifiers:Modifier[];
  isPizza:Boolean;
  isLoading = true;
  selectedItemTotal:number = 0
  selectedModifiersTotal:number = 0
  modCounter: Map<number, number>;
  isValid:boolean = false;
  store:Store;
  menuItemGroup;
  uiSetting:any;
  availableQty:number;
  currentIndex = 0;

  constructor(
    private route: ActivatedRoute,
    private orderService:OrderService,
    private router: Router,
    private storeService:StoreService
  ) { }

  ngOnInit() {
    this.orderId = this.route.snapshot.paramMap.get('orderId');
    this.storeId = this.route.snapshot.paramMap.get('storeId');
    this.menuItemGroupId = this.route.snapshot.paramMap.get('menuItemGroupId');

    this.menuItemId = this.route.snapshot.paramMap.get('menuItemId');
    this.parsedMenuItemId = this.route.snapshot.paramMap.get('parsedMenuItem');
    this.quantity = 1
    this.orderService.getOrder(this.orderId).subscribe(data => {
      let orderWithPayment = Object.assign(new OrdersWithPayment(), data);
      if(orderWithPayment.bot_order.is_submitted){
        this.router.navigate(['order', this.orderId, 'status', 'success']);
      }
    })

    this.orderService.getStoreInfo(this.storeId).subscribe(store => {
      this.store = Object.assign(new Store(), store);
    this.uiSetting = this.store.ui_settings;
      this.orderService.getMenuGroup(this.storeId, +this.menuItemGroupId).subscribe(data => {
        this.menuItemGroup = data;
        this.isPizza = data.menu_group.name.toLowerCase().includes("pizza")
      })

      this.orderService.getMenuItemGroupOptionsForStore(this.storeId, this.menuItemGroupId)
        .subscribe(data => {
          this.menuGroupItems = data
          if(this.menuItemId){
            this.selectedMenuItem = this.menuGroupItems.find(p => p.id === +this.menuItemId)
          }else{
            this.selectedMenuItem = this.menuGroupItems.filter(m => m.is_available)[0]
          }
          if(this.selectedMenuItem){
            this.availableQty = this.selectedMenuItem.available_qty;
            this.orderService.getModifiersForMenuItem(this.storeId, this.selectedMenuItem.id.toString()).subscribe(data => {
              this.title = data.menu_item.display_name;
              this.description = data.menu_item.description;
              this.modifierGroups = data.modifier_groups.sort((x,y) => Number(y.is_required) - Number(x.is_required)).map(p => Object.assign(new ModifierGroup(), p));
              this.selectedItemTotal = this.selectedMenuItem.base_price
            })
          }
          this.isLoading = false;
          if(this.parsedMenuItemId){
            this.orderService.getModifiers(this.orderId, this.parsedMenuItemId).subscribe(data => {
              this.specialRequest = data.parsed_menu_item.special_request;
              this.quantity = data.parsed_menu_item.quantity;
              let modifierOptions = Object.assign(new EditModifierOptions(), data);
              let existing_modifiers = modifierOptions.parsed_menu_item.modifiers.filter(m => m.modifier != null)
    
              this.selectedModifiers = existing_modifiers.map(m => Object.assign(new ModifierOption(), m));
              this.selectedModifiersTotal = +this.selectedModifiers.reduce((sum, mod) => sum + +mod.getUpcharge(), 0);
              this.isLoading = false;
              if (this.parsedMenuItemId && this.modifierGroups) {
                this.selectedModifiers.forEach(selectedMod => {
                  const modifierGroupId = selectedMod.modifier.modifier_group;
                  const modifierId = selectedMod.modifier.id;
                  const modifierGroup = this.modifierGroups.find(group => group.id === modifierGroupId);
                  if (modifierGroup) {
                    modifierGroup.options.forEach(option => {
                      if (option.id === modifierId) {
                        option.mod_type = selectedMod.mod_type;
                      }
                    });
                  }
                });
              }
            })
          }else{
            this.selectedModifiers = [];
          }
        })
    })
  }

  goBack() {
    window.history.back()
  }

  getQuantity(value:QuantityChange){
    console.log(value);

    this.quantity = value.quantity;
  }

  getSpecialRequest(value:string){
    this.specialRequest = value;
  }

  checkModifierConstrains(value){
    this.isValid = value
  }

  getSelectedModifiers(values:Modifier[]){
    this.selectedModifiersTotal = 0
    this.selectedModifiers = values;
    this.selectedModifiers.forEach(m => {
      if (m.modifier.half_mod_upcharge == null) {
        let upcharge = m.mod_type === "H1" || m.mod_type === "H2" ? +m.getUpcharge()/2.0 : +m.getUpcharge();
        this.selectedModifiersTotal  = +this.selectedModifiersTotal + upcharge
        
      } else {
        if (m.mod_type === "H1" || m.mod_type === "H2") {
          if (m.modifier.half_mod_upcharge == null) {
            let upcharge = m.mod_type === "H1" || m.mod_type === "H2" ? +m.getUpcharge()/2.0 : +m.getUpcharge();
            this.selectedModifiersTotal  = +this.selectedModifiersTotal + upcharge
          } else {
            let upcharge = m.getUpcharge();
            this.selectedModifiersTotal  = +this.selectedModifiersTotal + upcharge

          }
        } else {
          let upcharge = +m.getUpcharge();
          this.selectedModifiersTotal  = +this.selectedModifiersTotal + upcharge
        }
      }
    })
  }

  getPortionChange(value:MenuItem){
    this.isLoading = true
    this.selectedMenuItem = value;
    this.selectedModifiers = [];
    this.selectedModifiersTotal = 0
    this.orderService.getModifiersForMenuItem(this.storeId, this.selectedMenuItem.id.toString()).subscribe(data => {
      this.title = data.menu_item.display_name
      this.modifierGroups = data.modifier_groups.sort((x,y) => Number(y.is_required) - Number(x.is_required)).map(p => Object.assign(new ModifierGroups(), p));
      this.isLoading = false
      this.selectedItemTotal = this.selectedMenuItem.base_price
    })
  }

  updateItem(){
    if(this.parsedMenuItemId){
      this.orderService.updateItem(this.orderId, this.parsedMenuItemId, this.selectedMenuItem.id.toString(),
      this.selectedModifiers, this.quantity, this.specialRequest).subscribe(() => {
        this.router.navigate(['order', this.orderId]);
      });
    }else{
      this.orderService.addnewItem(this.orderId, this.selectedMenuItem.id.toString(), this.selectedModifiers,
        this.quantity, this.specialRequest).subscribe(() => {
        this.router.navigate(['order', this.orderId]);
      });
    }
  }
  prev() {
    this.currentIndex = (this.currentIndex > 0) ? this.currentIndex - 1 : this.menuItemGroup.latest_image.length - 1;
    console.log('Previous clicked, current index:', this.currentIndex);
  }

  next() {
    this.currentIndex = (this.currentIndex < this.menuItemGroup.latest_image.length - 1) ? this.currentIndex + 1 : 0;
    console.log('Next clicked, current index:', this.currentIndex);
  }
}
