import { Component, OnInit, Input, Output, ViewChild, OnChanges, Inject, HostListener } from '@angular/core';
import { StoreMenuItemsV2, MenuItemGroup, Store } from 'src/app/models/store';
import { StoreBottomSheetComponent } from 'src/app/store-bottom-sheet/store-bottom-sheet-component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router, ActivatedRoute } from '@angular/router';
import { OrdersWithPayment } from 'src/app/models/order';
import { OrderService } from 'src/app/order.service';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'store-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  displayedColumns: string[] = ['picture', 'item', 'price'];
  @Input() orderId: string;
  @Input() storeMenu: StoreMenuItemsV2;
  @Input() store: Store;
  @Input() orderWithPayment: OrdersWithPayment;
  @Input() filter: string;
  @Input() openAll;
  @Output() emitLazy


  isEnd = false
  limit = 50
  offset = 0
  selectedCategoryFragment;
  selectedSubCategoryFragment;
  uiSetting: any;
  constructor(
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private orderService: OrderService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document
  ) { }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler($event: any): void {
    localStorage.removeItem('menu_group_name');
    localStorage.removeItem('menu_sub_group_name');
  }

  ngOnInit() {
    this.uiSetting = this.store.ui_settings;
    this.route.fragment.subscribe(fragment => {
      // let element = <MatExpansionPanel>this.document.getElementById(fragment)
      this.selectedCategoryFragment = fragment
    });
    for (let key in this.storeMenu.menu_items) {
      let value = this.storeMenu.menu_items[key];
      value.setCurrentOffset = 0
      value.disabled = false;
    }

    /* Click Back Behaviour */
    const selectedMenuGroup = localStorage.getItem('menu_group_name');
    const selectedSubMenuGroup = localStorage.getItem('menu_sub_group_name');
    if (selectedMenuGroup) {
      this.selectedCategoryFragment = selectedMenuGroup;
      if (selectedSubMenuGroup) {
        this.selectedSubCategoryFragment = selectedSubMenuGroup
      } else {
        this.selectedSubCategoryFragment = null;
      }
    } else {
      this.selectedCategoryFragment = null;
    }
  }

  onSelect(item: MenuItemGroup) {
    if (this.orderId) {
      localStorage.setItem('menu_group_name', item['menu_group_name']);
      if (item['menu_sub_group_name']) {
        localStorage.setItem('menu_sub_group_name', item['menu_sub_group_name']);
      }
      this.router.navigate(['order', this.orderId, 'store', this.store.url_slug, 'menu-item-group', item.id])
    } else {
      localStorage.setItem('menu_group_name', item['menu_group_name']);
      if (item['menu_sub_group_name']) {
        localStorage.setItem('menu_sub_group_name', item['menu_sub_group_name']);
      }
      this.router.navigate(['store', this.store.url_slug, 'menu-item-group', item.id])
    }
  }

  onChangeMenu(e) {
    this.selectedCategoryFragment = e;
    this.selectedSubCategoryFragment = null;
    localStorage.removeItem('menu_group_name');
    localStorage.removeItem('menu_sub_group_name');
  }

  onChangeSubMenu(e) {
    this.selectedSubCategoryFragment = e;
    localStorage.removeItem('menu_sub_group_name');
  }

  openBottomSheet(): void {
    this.bottomSheet.open(StoreBottomSheetComponent, {
      panelClass: 'store-bottom-sheet-container',
      data: this.storeMenu
    });
  }

  disabledLoadMore(itemGroup) {
    return this.storeMenu.menu_items[itemGroup].disabled
  }

  loadMore(itemGroup, subgroup) {

    this.storeMenu.menu_items[itemGroup].setCurrentOffset = this.storeMenu.menu_items[itemGroup].setCurrentOffset + this.limit;
    let menu_group_id = this.storeMenu.menu_items[itemGroup][subgroup][0]['menu_group_id']
    this.orderService.getStoreMenuV2LazyIncremental(this.store.id, this.filter, this.limit.toString(),
      this.storeMenu.menu_items[itemGroup].setCurrentOffset.toString(), menu_group_id, itemGroup, subgroup).subscribe(data => {

        if (data.length < this.limit) {
          this.storeMenu.menu_items[itemGroup].disabled = true
        }
        this.storeMenu.menu_items[itemGroup][subgroup] = this.storeMenu.menu_items[itemGroup][subgroup].concat(data)
      })
  }

  public santize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

}
