import {BotOrder, Modifier, ModifierOption, Order, OrdersWithPayment} from '../../models/order';
import * as moment from 'moment/moment';
import {Store} from '../../models/store';

export class OrderHelpers{
  public static parsePayment(payment){
    let doubleFields = ['sub_total', 'tax', 'tip', 'discount', 'store_credit', 'total', 'delivery_charge', 'service_charge']
    doubleFields.forEach(f => payment[f] = parseFloat(payment[f]))
    return payment
  }

  public static parseOrder(data){
    return Object.assign(new OrdersWithPayment(), data, {
      bot_order: Object.assign(new BotOrder(), data.bot_order, {
        occasion_schedule: moment(data.bot_order.occasion_schedule, 'hh:mm A MMM DD, YYYY').toDate()
      }),
      items: data.items.map(o => Object.assign(new Order(), o, {
        modifiers: o.modifiers.map(x  => Object.assign(new Modifier(), x, {
          modifier: Object.assign(new ModifierOption(), x.modifier)
        }))
      })),
      store: Object.assign(new Store(), data.store),
      payment: this.parsePayment(data.payment)
    });
  }
}

