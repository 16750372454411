import {Component, Input} from '@angular/core';
import {MenuItemGroup, Store} from '../../models/store';
import {Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-menu-group-items',
  templateUrl: './menu-group-items.component.html',
  styleUrls: ['./menu-group-items.component.sass']
})
export class MenuGroupItemsComponent {

  @Input() store: Store;
  @Input() orderId: string;
  @Input() item: any;
  @Input() uiSetting: any;

  constructor(private router: Router,
              private sanitizer: DomSanitizer,) {
  }
  onSelect() {
    if (this.orderId) {
      localStorage.setItem('menu_group_name', this.item['menu_group_name']);
      if (this.item['menu_sub_group_name']) {
        localStorage.setItem('menu_sub_group_name', this.item['menu_sub_group_name']);
      }
      this.router.navigate(['order', this.orderId, 'store', this.store.url_slug, 'menu-item-group', this.item.id])
    } else {
      localStorage.setItem('menu_group_name', this.item['menu_group_name']);
      if (this.item['menu_sub_group_name']) {
        localStorage.setItem('menu_sub_group_name', this.item['menu_sub_group_name']);
      }
      this.router.navigate(['store', this.store.url_slug, 'menu-item-group', this.item.id])
    }
  }

  public santize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
