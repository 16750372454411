import { Component, OnInit, Input } from '@angular/core';
import { OrdersWithPayment } from 'src/app/models/order';
import { PaymentOption } from 'src/app/_services/payment-option.service';
import { GenericAlertComponent } from 'src/app/generic-alert/generic-alert.component';
import { Store } from 'src/app/models/store';
import { SurchargeLabelAlertComponent } from 'src/app/surcharge-label-alert/surcharge-label-alert.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-order-summary-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  @Input() store: Store
  @Input() orderWithPayment:OrdersWithPayment;
  @Input() paymentOption:PaymentOption;
  uiSetting:any;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    console.log('storee', this.store, this.orderWithPayment);

    this.uiSetting = this.store.ui_settings;
  }

  openDialog(title, description): void {
    const dialogRef = this.dialog.open(GenericAlertComponent, {
      width: '250px',
      data: {title: title, description: description}
    });
  }

  openSurchargeAlert(title, description): void {
    const dialogRef = this.dialog.open(SurchargeLabelAlertComponent, {
      width: '250px',
      data: {title: title, description: description}
    })
  }


}
