import { Component } from '@angular/core';

@Component({
  selector: 'app-cvv-dialog',
  templateUrl: './cvv-dialog.component.html',
  styleUrls: ['./cvv-dialog.component.sass']
})
export class CvvDialogComponent {

  constructor() { }
}
