import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from 'src/app/models/store';
import { OrderService } from 'src/app/order.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cashier-varification',
  templateUrl: './cashier-varification.component.html',
  styleUrls: ['./cashier-varification.component.sass']
})
export class CashierVarificationComponent {
  receivedData: any;
  createdAt: any;
  redeemId: any;
  redeemedAmount: any;
  otpValues: string[] = ['', '', '', '', '', ''];
  @ViewChild('input1') input1: ElementRef;
  @ViewChild('input2') input2: ElementRef;
  @ViewChild('input3') input3: ElementRef;
  @ViewChild('input4') input4: ElementRef;
  @ViewChild('input5') input5: ElementRef;
  @ViewChild('input6') input6: ElementRef;
  enteredOTP: any;
  phoneNumber: any;
  queryParam1: any;
  hash: any;
  idGet: any;
  store;
  offerCatalogSharableLink;
  isLoading = true;
  storeId: string;
  store_id: string;
  errorMessage1: string;
  referralVoucherData: any;
  nextPageShowHide = '1';
  referrerGetsDollar: any;
  referralVoucherData11: any;
  voucherId: any;
  allreferralVoucherData: any = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService,
  ) { }

  ngOnInit() {
    this.storeId = this.route.snapshot.paramMap.get('storeId');
    this.hash = this.route.snapshot.paramMap.get('userHash');
    this.phoneNumber = localStorage.getItem('phoneNumber')
    this.store_id = localStorage.getItem('currentStore')

    this.orderService.getStoreInfo(this.storeId).subscribe(store => {
      this.store = Object.assign(new Store(), store);

      if (this.store.dynamic_offer_url) {
        this.offerCatalogSharableLink = this.store.dynamic_offer_url;

      } else {
        this.offerCatalogSharableLink = `${environment.hostName}/store/${this.store.url_slug}/offers/`

      }
      this.isLoading = false;
    });
    this.referralVoucher()
    const navigationState = window.history.state;
    if (navigationState && navigationState.keySend) {
      this.receivedData = navigationState.keySend;
      this.phoneNumber = this.receivedData.phone_number
      this.queryParam1 = this.receivedData.referral_promotion
      this.idGet = this.receivedData.idGet
    } else {
      console.error('Data not found in state');
    }
  }

  focusNext(index: number) {
    if (index < this.otpValues.length) {
      const nextInput = this[`input${index + 1}`] as ElementRef;
      nextInput.nativeElement.focus();
    }
  }

  nextPage() {
    this.nextPageShowHide = '2';
  }
  firstBackNew() {
    this.router.navigate(['store/' + this.storeId + '/referral-menu']);
  }

  goBack() {
    window.history.back()
  }

  onLastInput() {
    this.enteredOTP = this.otpValues.join('');
  }
  referralVoucher() {
    let keySend = {
      "store_id": this.store_id,
      "phone_number": this.phoneNumber,
    }
    this.orderService.referralVoucher(keySend).subscribe(data => {
      if (data.data == '') {
        this.referralVoucherData = '';

        console.log('ifififif', this.referralVoucherData);

      }
      else {
        this.allreferralVoucherData = data.data
        this.referralVoucherData = data.data[0]
        this.referralVoucherData11 = data.data[0].referee_gets_dollar;
        this.referrerGetsDollar = data.data[0].referrer_gets_dollar;
        this.voucherId = data.data[0].id;
      }
    })
  }

  valueFilter(data: any) {

    this.voucherId = data.value.id;
    this.referralVoucherData = data.value
  }

  Verify() {
    let keySend = {
      "store_id": this.referralVoucherData.store,
      "pin": this.enteredOTP,
      "phone_number": this.referralVoucherData.phone_number,
      "referral_promotion": this.referralVoucherData.referral_promotion_id,
      "voucher_id": this.voucherId,
    }
    this.orderService.redeemAPi(this.voucherId, keySend).subscribe(data => {


      if (data.is_verified == true) {
        localStorage.setItem('redeem_id', data.redeem_id)
        localStorage.setItem('created_at', data.created_at)
        localStorage.setItem('redeemed_amount', data.redeemed_amount)

        this.router.navigate([this.router.url + '/thank']);
        this.otpValues = [];
      } else {
        this.otpValues = [];
        this.errorMessage1 = 'OTP verification failed. Please try again.';
      }

    })
  }
}
