import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { OrderService } from '../order.service';
import { Store } from '../models/store';
import { environment } from 'src/environments/environment';
import { Constants } from '../constants';


@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.sass']
})
export class OffersComponent implements OnInit {

  storeId:string;
  offerOrderId:string;
  store:Store;
  offers:any[];
  navigationSubscription;
  offerCatalogSharableLink;
  isLoading = true;
  offerOrder;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orderService:OrderService) {}

  ngOnInit() {
    this.storeId = this.route.snapshot.paramMap.get('storeId');
    this.offerOrderId = this.route.snapshot.paramMap.get('offerOrderId');
    if(!this.offerOrderId){
      let phoneNumber = localStorage.getItem(Constants.PHONE_NUMBER_KEY)
      if(phoneNumber){
        this.orderService.createTempOfferOrderWithPhone(this.storeId, phoneNumber).subscribe(data => {
          this.offerOrderId = data['offer_order_id']
          this.router.navigate(['store', this.storeId, 'offers', 'order', this.offerOrderId, 'available']);
        })
      }else{
        this.orderService.createTempOfferOrder(this.storeId).subscribe(data => {
          this.offerOrderId = data['offer_order_id']
          this.router.navigate(['store', this.storeId, 'offers', 'order', this.offerOrderId, 'available']);
        })
      }

    }else{
      this.orderService.getStoreInfo(this.storeId).subscribe(store => {
        this.store = Object.assign(new Store(), store);
        this.orderService.offers(this.storeId, this.offerOrderId).subscribe(offers => {
          this.offerOrder = offers.offer_order;
          this.offers = offers.offers;
        })
        if(this.store.dynamic_offer_url){
          this.offerCatalogSharableLink = this.store.dynamic_offer_url;
        }else{
          this.offerCatalogSharableLink = `${environment.hostName}/store/${this.store.url_slug}/offers/`
        }
        this.isLoading = false;
      });
    }
  }

  goToOffer(offer){
    this.router.navigate(['store', this.storeId, 'offers', 'order', this.offerOrderId, 'offer' , offer.id])
  }

  goBack(){
    this.router.navigate(['store', this.storeId, 'offers', 'order', this.offerOrderId]);
  }

}
