import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-redeem-confirmation-dialog',
  templateUrl: './redeem-confirmation-dialog.component.html',
  styleUrls: ['./redeem-confirmation-dialog.component.sass']
})
export class RedeemConfirmationDialogComponent implements OnInit {
  redeemedAmount: any;
  constructor(
    public dialogRef: MatDialogRef<RedeemConfirmationDialogComponent>,
    @Inject (MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {

    this.redeemedAmount = this.data.amount;

  }
  submit(){
    this.dialogRef.close(1)
  }

}
