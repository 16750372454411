import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BotOrder } from '../../models/order';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { OrderService } from 'src/app/order.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export class OrderStatus {
  declined: boolean;
  submitting: boolean;
  preparing: boolean;
  ready: boolean;
  deliveryStatus: string;
  status:string;

  constructor(order: BotOrder) {
    this.declined = order.is_cancelled;
    this.submitting = !order.view_notified && !order.ready_notified;
    this.preparing = order.view_notified && !order.ready_notified;
    this.ready = order.view_notified && order.ready_notified;
    this.deliveryStatus=order.delivery_status_doordash
    this.status=order.delivery_status
  }
}

@Component({
  selector: 'app-order-progress',
  templateUrl: './order-progress.component.html',
  styleUrls: ['./order-progress.component.sass']
})
export class OrderProgressComponent implements OnChanges {

  @Input() order: BotOrder;
  @Input() storeId: string;
  status: OrderStatus;
  trackingUrl: SafeResourceUrl | null = null;
  featureFlag: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    private orderService: OrderService,
    private snackBar: MatSnackBar
  ) {}
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes.order) {
      this.order = changes.order.currentValue;
      this.status = new OrderStatus(this.order);
      console.log(this.status);
      this.updateStatus();

    if (changes.storeId) {
      this.storeId = changes.storeId.currentValue;
      this.GetFeatureFlag(this.storeId, this.order.id, this.order.occasion); 
    }
  }
}

updateStatus() {
  this.status = new OrderStatus(this.order);
  console.log(this.status);
}

  getTrackingUrl(orderId: any) {
    this.orderService.getTrackingUrl(orderId).subscribe(
      (response: any) => {
        console.log('Tracking URL:-----', response);
        const trackingUrl = response.tracking_url;
        if (trackingUrl) {
          this.trackingUrl = this.sanitizer.bypassSecurityTrustResourceUrl(trackingUrl);
        } else {
          this.trackingUrl = null;
          // this.snackBar.open("Tracking URL not found.", "OK", { duration: 3000 });
        }
        this.updateStatus();
      },
      (error) => {
        console.error('Error fetching tracking URL:', error);
        this.trackingUrl = null;
        this.snackBar.open("Failed to fetch tracking URL.", "OK", { duration: 3000 });
      }
    );
  }

  GetFeatureFlag(storeId: string, orderId: any, occasion: string) {
    if (!storeId) {
      console.error('storeId is undefined.');
      return;
    }
    this.orderService.GetFeatureFlag(storeId).subscribe(
      (response: any) => {
        console.log('Feature Flag:', response.feature_flag);
        this.featureFlag = response.feature_flag;
        if (this.featureFlag === true && occasion === 'DELIVERY') {
          this.getTrackingUrl(orderId);
        }
      },
      (error) => {
        console.error('Error fetching feature flag:', error);
      }
    );
  }
}