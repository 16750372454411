import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from 'src/app/models/store';
import { OrderService } from 'src/app/order.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cashier-thank',
  templateUrl: './cashier-thank.component.html',
  styleUrls: ['./cashier-thank.component.sass']
})
export class CashierThankComponent {
  isLoading = true;
  storeId: string;
  store_id: string;
  phoneNumber: any;
  hash: any;
  store;
  offerCatalogSharableLink;
  Alldata: string;
  redeem_id: string;
  created_at: string;
  redeemed_amount: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService,
  ) { }

  ngOnInit() {
    this.storeId = this.route.snapshot.paramMap.get('storeId');
    this.hash = this.route.snapshot.paramMap.get('userHash');
    this.phoneNumber = localStorage.getItem('phoneNumber')
    this.store_id = localStorage.getItem('currentStore')

    this.redeem_id = localStorage.getItem('redeem_id')
    this.created_at = localStorage.getItem('created_at')
    this.redeemed_amount = localStorage.getItem('redeemed_amount')


    this.orderService.getStoreInfo(this.storeId).subscribe(store => {
      this.store = Object.assign(new Store(), store);
      if (this.store.dynamic_offer_url) {
        this.offerCatalogSharableLink = this.store.dynamic_offer_url;
      } else {
        this.offerCatalogSharableLink = `${environment.hostName}/store/${this.store.url_slug}/offers/`
      }
      this.isLoading = false;

    });
  }
  goBack() {
    window.history.back()
  }
}
