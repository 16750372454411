import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OrdersWithPayment } from 'src/app/models/order';
import { OrderService } from 'src/app/order.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.sass']
})
export class PromoCodeComponent implements OnInit {

  @Input() orderWithPayment:OrdersWithPayment;
  @Output() refresh = new EventEmitter<boolean>();

  promoCodeApplied: boolean;
  conditionsMet: boolean;
  expand: boolean = false;

  constructor(private orderService: OrderService,
    private router: Router) { }

  ngOnInit(): void {
    this.promoCodeApplied = this.orderWithPayment.bot_order.promo_code.length > 0
    this.conditionsMet = +this.orderWithPayment.payment.promotional_discount > 0;
  }

  removePromoCode(){
    this.orderService.removePromoCode(this.orderWithPayment.bot_order.order_hash).subscribe(data => {
      this.promoCodeApplied = false;
      this.refresh.emit(true)
    })
  }

  applyPromoCode(code){
    this.orderService.applyPromoCode(this.orderWithPayment.bot_order.order_hash, code).subscribe(data => {
      this.refresh.emit(true)
    },error=>{
      alert('Invalid Promo Code Entered!')
    })
  }

  goToPromo(){
    this.router.navigate(['order',
      this.orderWithPayment.bot_order.order_hash,
      'store',
      this.orderWithPayment.store.url_slug,
      'order-offers']);
  }

}
