import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrderService } from 'src/app/order.service';
import { Constants } from 'src/app/constants';
import { Router } from '@angular/router';
import { MyErrorStateMatcher } from '../instruction/instruction.component';


@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.sass']
})
export class UserLoginComponent implements OnInit {

  phoneNumber: string;
  otpSent: boolean = false;
  otp: number;
  errorMatcher = new MyErrorStateMatcher();


  constructor(private orderService: OrderService,
    public dialogRef: MatDialogRef<UserLoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,private router: Router,
  ) {

  }

  ngOnInit() {

  }

  getPlainPhoneNumberFromString(phoneNumber: string) {
    let ph = phoneNumber.replace(/[\s()-]/g, '')
    return '+1' + ph.substring(0, 10)
  }

  updatePhoneNumber(phoneNumber: string) {
    this.phoneNumber = phoneNumber;
    if (this.getPlainPhoneNumberFromString(phoneNumber).length === 12) {
      let plainPhoneNumber = this.getPlainPhoneNumberFromString(phoneNumber);
      const phone_number_check = plainPhoneNumber.replace(/\+/g, '');
      this.orderService.checkNumberBlocked(this.data.store, phone_number_check).subscribe((data: any) => {
        if (data.data == false) {
          this.orderService.otpForOffer(this.data.store, null, plainPhoneNumber, true).subscribe(() => {
            this.otpSent = true;
          })
        } else {
          this.snackBar.open('Your phone number is blocked', "OK", {
            verticalPosition: 'top'
          })
        }
      });

    }
  }

  getPlainPhoneNumber() {
    if (this.phoneNumber) {
      let ph = this.phoneNumber.replace(/[\s()-]/g, '')
      return '+1' + ph.substring(0, 10)
    }
    return "";
  }

  cancel() {
    this.dialogRef.close(false);
  }

  verifyOTP() {
    this.orderService.verifyOtpForOffer(this.data.store, null, this.getPlainPhoneNumber(), this.otp, true).subscribe((data: any) => {
      localStorage.setItem(Constants.PHONE_NUMBER_KEY, this.getPlainPhoneNumber())
      this.dialogRef.close(true);
      this.router.navigate(['store', this.data.store, data.phone_hash, 'wallet']);
      
    })
  }
}
