import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

export class QuantityChange {
  inc: boolean;
  quantity: number;

  constructor(inc, qty) {
    this.inc = inc;
    this.quantity = qty;
  }
}


@Component({
  selector: 'menu-item-quantity-control',
  templateUrl: './quantity-control.component.html',
  styleUrls: ['./quantity-control.component.scss']
})
export class QuantityControlComponent implements OnInit {

  @Output() quantityChange = new EventEmitter<QuantityChange>();
  @Input() quantity: number;
  @Input() maxQuantity: number;


  constructor() { }

  ngOnInit() { }

  incrementQuantity() {
    console.log(this.maxQuantity);

    if ((this.maxQuantity > 0) || this.maxQuantity === -1) {
      this.quantity = this.quantity + 1;
      const change = new QuantityChange(true, this.quantity)
      this.quantityChange.emit(change);

    }

    // if((this.maxQuantity > 0)){
    //   if(this.quantity < this.maxQuantity){
    //     this.quantity = this.quantity + 1;
    //     const change = new QuantityChange(true, this.quantity)
    //     this.quantityChange.emit(change);
    //   }
    // } else if(this.maxQuantity === -1) {
    //   this.quantity = this.quantity + 1;
    //   const change = new QuantityChange(true, this.quantity)
    //   this.quantityChange.emit(change);
    // }
  }

  decrementQuantity() {
    if (this.quantity > 1) {
      this.quantity = this.quantity - 1
      const change = new QuantityChange(false, this.quantity)
      this.quantityChange.emit(change);
    }
  }
}
