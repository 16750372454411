import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { OrdersWithPayment, Order } from 'src/app/models/order';
import { OrderService } from 'src/app/order.service';
import { Router } from '@angular/router';
import { Constants } from 'src/app/constants';
import { Store } from 'src/app/models/store';

@Component({
  selector: 'app-order-summary-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss']
})
export class ItemListComponent implements OnInit {
  @Input() store:Store;
  @Input() orderHash:string;
  @Input() orderWithPayment:OrdersWithPayment;
  @Input() isAdmin:boolean;
  @Output() quantityChange = new EventEmitter<number>()
  @Output() promoConditionsMetChange = new EventEmitter<boolean>();

  formQuantity = Array.from(Array(500).keys()).map(n => n+1);
  displayedColumns: string[] = ['quantity', 'item', 'itemTotal'];
  pizzaOptions:Map<string, string> = Constants.pizzaOptions;
  pizzaOptions$:Map<string, string> = Constants.pizzaOptionsFlag;
  promoHidden:boolean = true;
  promoCode
  promoCodeApplied = false;
  conditionsMet;
  uiSetting:any;

  constructor(
    private orderService:OrderService,
    private router: Router,
  ) { }

  getFormQuantity(maxQauntity){
    return Array.from(Array(maxQauntity).keys()).map(n => n+1);
  }

  ngOnInit() {
    this.uiSetting = this.store.ui_settings;
    console.log(this.orderWithPayment.items[0].getItemTotal());
    this.promoCode = this.orderWithPayment.bot_order.promo_code
    this.promoCodeApplied = this.orderWithPayment.bot_order.promo_code.length > 0
    this.promoHidden = !(this.orderWithPayment.bot_order.promo_code.length > 0);
    this.conditionsMet = +this.orderWithPayment.payment.promotional_discount > 0;
    if(this.promoCodeApplied){
      this.promoConditionsMetChange.emit(this.conditionsMet)
    }
  }

  updateQuantity(item:Order){
    if(item.quantity <= 0){
      this.deleteItem(item);
    }else{
      this.orderService.updateQuantity(this.orderHash, item.id, item.quantity).subscribe(data => {
        this.ngOnInit();
        this.quantityChange.emit(1)
      })
    }
  }

  deleteItem(item:Order){
    this.orderService.deleteItem(this.orderHash, item.id).subscribe(data =>{
      this.quantityChange.emit(1)
    })
  }

  onSelect(item:Order){
    // parsed_item_id = item.id
    this.router.navigate(['order', this.orderHash,
      'store', this.orderWithPayment.store.id,
      'menu-item-group', item.menu_item.menu_item_group,
      'menu-item',item.menu_item.id,
      'parsed-menu-item',item.id, 'modifiers']);
  }

  goBack(){
    localStorage.removeItem('menu_group_name');
    localStorage.removeItem('menu_sub_group_name');
    this.router.navigate(['order', this.orderHash, 'store', this.orderWithPayment.store.id])
  }

  togglePromoCode(){
    this.promoHidden = !this.promoHidden;
  }

  applyPromoCode(code){
    this.orderService.applyPromoCode(this.orderHash, code).subscribe(data => {
      this.promoConditionsMetChange.emit(this.promoCodeApplied && this.conditionsMet)
      this.quantityChange.emit(1)
    },error=>{
      this.promoCode = null;
      alert('Invalid Promo Code Entered!')
    })
  }

  removePromoCode(){
    this.orderService.removePromoCode(this.orderWithPayment.bot_order.order_hash).subscribe(data => {
      this.promoConditionsMetChange.emit(true)
      this.quantityChange.emit(1)
    })
  }

}
