import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderService } from 'src/app/order.service';
import { Store } from 'src/app/models/store';
import { LoyaltyPointService } from 'src/app/_services/loyalty-point.service';

@Component({
  selector: 'app-points-redeem-history',
  templateUrl: './points-redeem-history.component.html',
  styleUrls: ['./points-redeem-history.component.sass']
})
export class PointsRedeemHistoryComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private orderService: OrderService,
    private router: Router,
    private loyaltyPointService: LoyaltyPointService) { }

  isLoading = true;
  hash
  storeId;
  store;
  storeid: any;
  pointHistory;
  monthlyExpiringPoints: Number;
  weeklyExpiringPoints: Number;
  

  ngOnInit() {
    this.hash = this.route.snapshot.paramMap.get('hash');
    this.storeId = this.route.snapshot.paramMap.get('storeId');
    this.storeid = localStorage.getItem('currentStore');

    this.orderService.getStoreInfo(this.storeId).subscribe(store => this.store = Object.assign(new Store(), store))
    this.loyaltyPointService.getPointRedeemHistory(this.storeid, this.hash).subscribe((data: any) => {
      this.pointHistory = data.data.reverse();
      this.monthlyExpiringPoints = data.monthly_expiring_points;
      this.weeklyExpiringPoints = data.weekly_expiring_points;
      this.isLoading = false;
    });
  }

  // goTo(item) {
  //   this.router.navigate([this.hash, 'my-offers', item.offer_item.id, 'redeem', item.id, 'status'])
  // }

  goBack() {
    window.history.back()
  }

}
