import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Store } from '../models/store';

@Injectable()
export class StoreService{
  endpoint = environment.orderApiBackend;
  storeEndpointV2 = `${this.endpoint}/backend/v2/stores/`;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    })
  };

  private source = new BehaviorSubject<Store>(null);
  current = this.source.asObservable();


  constructor(private http: HttpClient){}

  changeStore(store: Store) {
    this.source.next(store);
    localStorage.setItem("currentStore", store.id)
  }

}
