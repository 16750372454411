import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { CardDetails } from '../models/order';


@Injectable({
  providedIn: 'root'
})
export class GuestPaymentDetailService {
  endpoint = environment.orderApiBackend;
  token = environment.accessToken;
  // graphmenu = this.endpoint;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Token ${this.token}`,
    }),
  };

  constructor(private http: HttpClient) { }

  getGuestPaymentDetail(token: any, storeId: any) {
    let params = new HttpParams().set('store_id', storeId)
    return this.http.get<CardDetails>(`${this.endpoint}/backend/v2/gpd/${token}/get_card_by_token`,
      {params: params, headers: this.httpOptions.headers});
  }

  getAllCards(storeId: any, phoneNumer: any, paymentGateway: any) {
    let params = new HttpParams()
      .set('phone_number', phoneNumer)
      .set('store_id', storeId)
      .set('payment_gateway', paymentGateway);


    return this.http.get<CardDetails[]>(`${this.endpoint}/backend/v2/gpd/${storeId}/get_all_cards/`,
      {params: params, headers:this.httpOptions.headers});
  }
}
