import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CardDetails } from '../models/order';
import { OrderService } from '../order.service';

export class PaymentOption{
  cash:boolean;
  card:boolean;
  storeCredit:boolean;
  cardDetail:CardDetails;
  storeCreditBalance:number;
  mobilePaymentService: boolean;

  constructor(cash:boolean, card:boolean, storeCredit:boolean,
      cardDetail:CardDetails, storeCreditBalance:number=0,mobilePaymentService=false){
    this.cash=cash;
    this.card=card;
    this.storeCredit=storeCredit;
    this.cardDetail=cardDetail;
    this.storeCreditBalance=storeCreditBalance;
    this.mobilePaymentService = mobilePaymentService;
  }

  hasPaymentOption(){
    return this.cash || this.card || this.storeCredit || (this.storeCredit && this.cash) || (this.storeCredit && this.card) || this.mobilePaymentService;
  }

  isMobilePaymentService(){
    this.mobilePaymentService = true;
  }
}

@Injectable()
export class PaymentOptionService{
  private source = new BehaviorSubject<PaymentOption>(null);
  current = this.source.asObservable();

  constructor(){}

  updatePaymentOption(paymentOption: PaymentOption) {
    if(paymentOption){
      this.source.next(paymentOption);
    }

  }
}
