import { Component, AfterViewInit, OnInit } from '@angular/core';
import { ConfigService } from './config.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { FaviconService } from './_services/favicon.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

interface ApiResponse {
  id: number;
  store: number;
  favicon_file: string;
}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  endpoint = environment.orderApiBackend;
  token = environment.accessToken;




  title:string;
  apiUrl: any;
  accessToken: any;
  idStore: any;
  constructor(private configService:ConfigService, private router: Router,
    private route: ActivatedRoute, private http: HttpClient, private faviconService: FaviconService){
    this.idStore = localStorage.getItem("currentStore");
    this.apiUrl = `${this.endpoint}/backend/v2/stores/favicon/${this.idStore}`;
    this.accessToken = `Token ${this.token}`;
    this.router.events.subscribe(event => { 
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
      }
    });
  }

  ngOnInit(){
    this.configService.title.subscribe((val:string) => {
      this.title = val;
      this.fetchFavicon();
    })
  }

  fetchFavicon(): void {
    const headers = new HttpHeaders().set('Authorization', `${this.accessToken}`);
    
    this.http.get<ApiResponse>(this.apiUrl, { headers })
      .subscribe(response => {
        const iconUrl = response.favicon_file;
        this.faviconService.setFavicon(iconUrl);
      });
  }
  goBack() {
    window.history.back()
  }

}
