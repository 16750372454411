import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OrderService } from 'src/app/order.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-offer-preview-list',
  templateUrl: './offer-preview-list.component.html',
  styleUrls: ['./offer-preview-list.component.sass']
})
export class OfferPreviewListComponent implements OnInit {

  constructor(private orderService:OrderService,
    private router: Router) { }

  @Input() storeId;
  @Input() offerOrderId;
  @Output() offerChange = new EventEmitter<number>()
  isLoading = true

  offers;

  ngOnInit() {
    this.orderService.offers(this.storeId, this.offerOrderId).subscribe(offers => {
      this.offers = offers;
      this.isLoading = false;
    })
  }

  goto(offer){
    this.router.navigate(['store', this.storeId, 'offers', 'order', this.offerOrderId, 'offer', offer.id]);
    this.offerChange.emit(offer.id)
  }
}
