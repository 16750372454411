import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderService } from 'src/app/order.service';

@Component({
  selector: 'app-multi-store-location',
  templateUrl: './multi-store-location.component.html',
  styleUrls: ['./multi-store-location.component.sass']
})
export class MultiStoreLocationComponent implements OnInit {

  store_id: any;
  constructor(private orderService: OrderService, 
    public dialogRef: MatDialogRef<MultiStoreLocationComponent>,
    @Inject (MAT_DIALOG_DATA) public data) { 
    
  }

  ngOnInit(): void {
    this.store_id = localStorage.getItem('currentStore');
  }

  storeSelection(id) {
    this.store_id = id; 
  }

  confirm() {
    this.dialogRef.close(this.store_id);
  }
  
}
