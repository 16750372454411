import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-gifted-status',
  templateUrl: './gifted-status.component.html',
  styleUrls: ['./gifted-status.component.sass']
})
export class GiftedStatusComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    let giftId = this.route.snapshot.paramMap.get('giftId');
    let storeId = this.route.snapshot.paramMap.get('storeId');
    let userHash = this.route.snapshot.paramMap.get('userHash');
    this.router.navigate(['store', storeId , userHash, 'my-offers'])
  }

}
