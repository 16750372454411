import { Component, ElementRef, ViewChild } from '@angular/core';
import { OrderService } from '../order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { Store } from '../models/store';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgNavigatorShareService } from 'ng-navigator-share';

@Component({
  selector: 'app-userapp-referral',
  templateUrl: './userapp-referral.component.html',
  styleUrls: ['./userapp-referral.component.sass']
})
export class UserappReferralComponent {
  store;
  offerCatalogSharableLink;
  storeId: any;
  isLoading = true;
  store_id: any;
  hash: any;
  phoneNumber: any;
  nextPageShowHide = '3';
  form: FormGroup;
  nextVerifyShowHide: boolean = false;
  nextShareShwHide: boolean = false;
  idSet: any;
  otpValues: string[] = ['', '', '', '', '', ''];
  @ViewChild('input1') input1: ElementRef;
  @ViewChild('input2') input2: ElementRef;
  @ViewChild('input3') input3: ElementRef;
  @ViewChild('input4') input4: ElementRef;
  @ViewChild('input5') input5: ElementRef;
  @ViewChild('input6') input6: ElementRef;
  enteredOTP: string;
  errorMessage: any = '';
  selected = new FormControl('');
  promotionData: any = [];
  firstPageHideShow: any = '1';
  promotionId: any;
  FilterPromot: any;
  referralCode: any;
  errorMessage1: string;
  errorMessage2: string;
  referralCodeSharableLink: string;
  locationhost: string;
  selectedPromotionId: any = null;
  default_msg: any;
  referralCode1: any;
  getId: string;
  FilterPromotShow: any;
  // hideHeader: boolean = false;
  constructor(
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    ngNavigatorShareService: NgNavigatorShareService,
  ) {
    this.ngNavigatorShareService = ngNavigatorShareService;
  }
  private ngNavigatorShareService: NgNavigatorShareService;

  ngOnInit(): void {

    this.storeId = this.route.snapshot.paramMap.get('storeId');
    this.hash = this.route.snapshot.paramMap.get('userHash');
    this.phoneNumber = localStorage.getItem('phoneNumber')
    this.store_id = localStorage.getItem('currentStore')
    this.getId = this.route.snapshot.paramMap.get('id')


    
    this.orderService.getStoreInfo(this.storeId).subscribe(store => {
      this.store = Object.assign(new Store(), store);
      if (this.store.dynamic_offer_url) {
        this.offerCatalogSharableLink = this.store.dynamic_offer_url;
      } else {
        this.offerCatalogSharableLink = `${environment.hostName}/store/${this.store.url_slug}/offers/`
      }
      this.isLoading = false;
    });
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
    });
    this.promotionList();
  }
  valueFilter(e: any) {
    this.promotionId = e.value;

  }
  goBack() {
    window.history.back()
  }
  nextPage() {
    this.nextPageShowHide = '2';
  }
  nextVerify() {
    this.nextPageShowHide = '';
    this.nextVerifyShowHide = true;
  }
  nextShare() {
    this.nextShareShwHide = true;
    this.nextVerifyShowHide = false;
  }
  firstPage() {
    this.firstPageValiCheck();
    this.firstPageHideShow = '1';
    if (this.promotionData.length === 1) {
      this.promotionId = this.selectedPromotionId;
    }

    if (this.promotionId != undefined) {
      this.FilterPromot = this.promotionData.find(promotion => promotion.id === this.promotionId);
      localStorage.setItem('referee_gets', JSON.stringify(this.FilterPromot));

      this.router.navigate(['store/' + this.storeId + '/referral-promotion']);
    }
  }

  promotionList() {
    this.orderService.promotionList(this.storeId).subscribe(data => {
      this.promotionData = data.data
      localStorage.setItem('currentStore', data.store_id)
      if (this.promotionData.length === 1) {
        this.selectedPromotionId = this.promotionData[0].id;
      }
      this.FilterPromotShow = this.promotionData.find(promotion => promotion.id == this.getId);

    });

  }
  firstBackNew() {
    this.router.navigate(['store/' + this.storeId + '/referral-menu']);
  }
  firstBack() {
    this.firstPageHideShow = '1';
    this.nextPageShowHide = '';
  }
  secondBack() {
    this.nextPageShowHide = '';
    this.nextPageShowHide = '1';
  }
  thirdBack() {
    this.nextVerifyShowHide = false;
    this.nextPageShowHide = '2';
  }
  fourthBack() {
    this.nextShareShwHide = false;
    this.nextPageShowHide = '2';
  }


  firstPageValiCheck() {
    this.store_id = localStorage.getItem('currentStore')
    let keySend = {
      "store_id": this.store_id,
      "referral_promotion_id": this.getId,
      "phone_number": this.phoneNumber,
      "referee_name": ''
    }

    this.orderService.refereeRegistration(keySend).subscribe(data => {
      if (data.is_verified == "true") {
        this.router.navigate(['store/' + this.storeId + '/referral-promotion-code']);
      }
    })
  }

  referralLink(link1) {
    let keySend = {
      "url": link1,
    }
    this.orderService.referralLink(keySend).subscribe(data => {
      this.referralCode1 = data.data
    });
  }

  refereeRegistrationSubmit(): void {
    this.store_id = localStorage.getItem('currentStore')
    if (this.form.value.name == '' || this.form.value.phone == '') {
      this.errorMessage = 'Input filed are required.';
      return;
    }
    let keySend = {
      "store_id": this.store_id,
      "referral_promotion_id": this.promotionId,
      "phone_number": '+1' + this.form.value.phone,
      "referee_name": this.form.value.name
    }
    this.orderService.refereeRegistration(keySend).subscribe(data => {
      this.default_msg = data.default_msg;
      if (data.is_verified == "true") {
        this.referralCode = data.referral_code;
        localStorage.setItem('phoneNumber', keySend.phone_number)

        this.referralCodeSharableLink = `${location.host}/store/${this.store.url_slug}/${this.hash}/referral/${this.referralCode}/${this.promotionId}/${this.store_id}`;
        this.locationhost = location.host;
        this.referralLink(this.referralCodeSharableLink)
        this.nextPageShowHide = '';
        this.nextShare();
        // this.errorMessage2 = 'phone number already exists';
      }
      else {
        this.refereeRegistrationOTP(data.data);
        this.phoneNumber = data.data.phone_number;
        this.idSet = data.data.id;
        this.nextVerify();
      }

    });
  }

  refereeRegistrationOTP(data) {
    this.store_id = localStorage.getItem('currentStore')
    this.idSet = data.id
    let keySend = {
      "store_id": this.store_id,
      "phone_number": data.phone_number,
      "referee_name": data.referee_name
    }
    this.orderService.refereeRegistrationOTP(this.idSet, keySend).subscribe(data => {
    });
  }
  focusNext(index: number) {
    if (index < this.otpValues.length) {
      const nextInput = this[`input${index + 1}`] as ElementRef;
      nextInput.nativeElement.focus();
    }
  }
  onLastInput() {
    this.enteredOTP = this.otpValues.join('');
  }

  verifyOTP() {
    this.store_id = localStorage.getItem('currentStore')
    let keySend = {
      "phone_number": this.phoneNumber,
      "otp": this.enteredOTP,
      "store_id": this.store_id,
      "promotionId": this.promotionId,
      "referral_promotion": this.promotionId,
      "user_hash": this.hash,
      "url_slug": this.store.url_slug,
      "url_host": location.host,
    }
    this.orderService.verifyOTP(this.idSet, keySend).subscribe(data => {
      if (data.is_verified == true) {
        this.referralCode = data.referral_code;
        this.referralCodeSharableLink = `${location.host}/store/${this.store.url_slug}/${this.hash}/referral/${this.referralCode}/${this.promotionId}/${this.store_id}`;
        this.locationhost = location.host;

        this.nextShare();
      } else {
        this.otpValues = [];
        this.errorMessage1 = 'OTP verification failed. Please try again.';
      }
    });
  }

  copyMessage(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.snackBar.open('Copied', "OK", {
      duration: 5000,
      verticalPosition: 'bottom'
    })
  }

  shareHigth(offer) {
    console.log(offer);

    console.log(this.router.url);
    let url = this.referralCode1;
    if (!this.ngNavigatorShareService.canShare()) {
      this.copyMessage(url);
      return;
    }
    this.ngNavigatorShareService.share({
      title: this.default_msg,
      text: this.default_msg + '\n\n',
      url: `store/${this.store.url_slug}/${this.hash}/referral/${this.referralCode}/${this.promotionId}/${this.store_id}`,
    }).then((response) => {
      console.log(response);
    })
      .catch((error) => {
        console.log(error);
        window.location.reload();
      });
  }


}
