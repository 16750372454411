import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-progress-steps',
  templateUrl: './progress-steps.component.html',
  styleUrls: ['./progress-steps.component.sass']
})
export class ProgressStepsComponent implements OnInit, OnChanges{

  @Input() totalPoints: number = 100;
  @Input() tt: any[];
  progress = []

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if(changes.totalPoints){
      this.totalPoints = changes.totalPoints.currentValue;
    }

    if(changes.tt){
      this.tt = changes.tt.currentValue;
      this.progress = this.getTierProgress(this.tt);
    }
  }

  ngOnInit() {
    if(this.tt) {
      console.log(this.tt)
      this.progress = this.getTierProgress(this.tt);
      console.log(this.progress);
    }
  }

  getTierProgress(tt: any[]) {
    let progress = []
    let i = 0;
    for (; i < tt.length; i++) {
      let current = tt[i].points;
      let previous = tt[i - 1] ? tt[i - 1].points : 0;

      if(this.totalPoints >= current){
        progress.push({status: 'COMPLETED', points: current});
      }

      if(this.totalPoints < current){
        progress.push({status: 'CURRENT', points: current});
        i++;
        break;
      }
    }

    for(; i < tt.length; i++) {
      progress.push({status: 'UPCOMING', points: tt[i].points});
    }
    console.log(progress);
    return progress;
  }
}
