import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-surcharge-label-alert',
  templateUrl: './surcharge-label-alert.component.html',
  styleUrls: ['./surcharge-label-alert.component.sass']
})
export class SurchargeLabelAlertComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SurchargeLabelAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close()
  }

}
