import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
selector: '[moveNextByMaxLength]'
})
export class MoveNextByMaxLengthDirective {

constructor(private _el: ElementRef) { }

  @HostListener('keyup', ['$event']) onKeyDown(e: any) {
    if (e.srcElement.maxLength === e.srcElement.value.length) {
      e.preventDefault();
      let nextControl: any = e.srcElement.nextSibling.nextSibling;
    }
  }
}
