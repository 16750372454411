import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OccasionDetail } from '../models/order';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';


@Injectable()
export class OrderProcessingService{
  endpoint = environment.orderApiBackend;
  token = environment.accessToken;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: `Token ${this.token}`,
    })
  };

  constructor(private http: HttpClient){}

  private source = new BehaviorSubject<OccasionDetail>(null);
  current = this.source.asObservable();

  postPaymentProcess(order_hash: string, is_success: boolean) {
    const payload = {is_success: is_success};
    return this.http.post(`${this.endpoint}/backend/v2/order-processing/${order_hash}/post_payment_process/`, payload, this.httpOptions);
  }
}
