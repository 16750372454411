import { Component, OnInit } from '@angular/core';
import { OrderService } from '../order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersWithPayment, BotOrder } from '../models/order';
import { Store } from '../models/store';
import { StoreService } from '../_services/store.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.sass']
})
export class HistoryComponent implements OnInit {
  orderHash;
  orderWithPayment;
  store;
  isLoading;

  history
  storeId
  userHash
  searchTerm:string = '';

  constructor(private orderService:OrderService,
    private storeService: StoreService,
    private route: ActivatedRoute,
    private router: Router,) { }

  ngOnInit(): void {
    this.storeId = this.route.snapshot.paramMap.get('storeId');
    this.userHash = this.route.snapshot.paramMap.get('userHash');
    this.orderService.getStoreInfo(this.storeId).subscribe(store => {
      this.store = Object.assign(new Store(), store);
      this.getHistory()
    })
  }

  getHistory(){
    this.orderService.getOrderHistory(this.storeId, this.userHash, this.searchTerm).subscribe(history => {
      this.history = history
    })
  }

  goto(order){
    this.router.navigate(['order', order.order_hash, 'status', 'success'],{queryParams: {'reorder': order.version === order.latest_version}})
  }

  goBack(){
    window.history.back()
  }

}
