import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from 'src/app/order.service';
import { MyErrorStateMatcher } from 'src/app/_helpers/error-matcher';
import { Constants } from 'src/app/constants';
import {Store} from '../../models/store';

@Component({
  selector: 'app-gifted',
  templateUrl: './gifted.component.html',
  styleUrls: ['./gifted.component.sass']
})
export class GiftedComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private router: Router,
    private orderService:OrderService) { }

  giftId;
  gift
  store
  offerCatalogSharableLink
  isLoading

  ends
  otp
  otpSent
  phoneNumber


  errorMatcher = new MyErrorStateMatcher();

  ngOnInit(): void {
    this.isLoading = true;
    this.giftId = this.route.snapshot.paramMap.get('giftId');
    this.orderService.getGiftDetails(this.giftId).subscribe(gift => {
      if(gift){
        this.gift = gift
        this.orderService.getStoreInfo(this.gift.offer_item.offer.store).subscribe(store => {
          this.store = Object.assign(new Store(), store)
          if(localStorage.getItem(this.gift.gifted_offer_order.id) === 'gifted'){
            this.router.navigate(['store', this.store.url_slug ,this.gift.gifted_offer_order.offer_order.encoded_id, 'my-offers'])
          }
          this.isLoading = false;
        })
      }
    })
  }

  claimNow(){
    this.orderService.claimGift(this.giftId, this.getPlainPhoneNumber()).subscribe(response => {
      this.router.navigate(['gift', this.giftId , this.store.url_slug, response.hash, 'success'])
    })
  }

  goBack(){}

  getFormattedPhoneNumber(phoneNumber){
    return phoneNumber.replace(/^(\+1)(\d{0,3})(\d{0,3})(\d{0,4})/, '($2) $3-$4')
  }

  updatePhoneNumber(phoneNumber:string){
    if(this.getPlainPhoneNumberFromString(phoneNumber).length === 12){
      this.sendOTP()
    }
  }

  getPlainPhoneNumberFromString(phoneNumber:string){
    let ph = phoneNumber.replace(/[\s()-]/g, '')
    return '+1' + ph.substring(0,10)
  }

  getPlainPhoneNumber(){
    if(this.phoneNumber){
      let ph = this.phoneNumber.replace(/[\s()-]/g, '')
      return '+1' + ph.substring(0,10)
    }
    return "";
  }

  sendOTP(){
    this.orderService.otpForOffer(this.store.id, this.gift.offer_item.offer_order.offer_order_id, this.getPlainPhoneNumber(), true).subscribe(() => {
      this.otpSent = true;
    })
  }

  verifyOTP(){
    this.orderService.verifyOtpForOffer(this.store.id, this.gift.offer_item.offer_order.offer_order_id,
      this.getPlainPhoneNumber(), this.otp, true).subscribe(data => {
        localStorage.setItem(Constants.PHONE_NUMBER_KEY, this.getPlainPhoneNumber())
        this.gift.gifted_offer_order.phone_number_verified = data['is_verified']
        this.claimNow();
      })
  }

  changePhone(){
    this.gift.phone_number_verified = false;
  }


}
