import { Component, OnInit, Inject, Input, Output, EventEmitter, NgZone, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef, MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';
import { CardDetails, PaymentMethods, OrdersWithPayment, OccasionDetail } from 'src/app/models/order';
import { PaymentComponent as AddPaymentMethodComponent } from 'src/app/payment/payment.component';
import { OrderService } from 'src/app/order.service';
import { PaymentStripeComponent } from 'src/app/payment-stripe/payment-stripe.component';
import { PaymentOption, PaymentOptionService } from 'src/app/_services/payment-option.service';
import { PaymentGatewayComponent } from 'src/app/payment-gateway/payment-gateway.component';
import { get } from 'scriptjs';
import { Store } from 'src/app/models/store';
import { BooleanService, Flag } from 'src/app/_services/boolean.service';
import { StoreService } from 'src/app/_services/store.service';
import { OccasionDetailService } from 'src/app/_services/occasion-detail.service';
import { NmiPaymentComponent } from '../nmi-payment/nmi-payment.component';
import {MatSnackBar} from '@angular/material/snack-bar';

declare var iTransact
declare var CollectJS


@Component({
  selector: 'app-payment-methods-bottom-sheet',
  templateUrl: './payment-methods-bottom-sheet.html',
  styleUrls: ['./payment-methods-bottom-sheet.scss'],
})
export class PaymentMethodsBottomSheetComponent implements OnInit, AfterViewInit{
  route:string;
  selectedPaymentMethod:CardDetails;
  cardDetails:CardDetails[];
  editCardDetails:boolean = false;
  toConfirmDelete:boolean = false;
  toDelete:CardDetails;
  paymentGateway;
  storeCreditBalance = 0;
  orderTotal = 0;
  storeCreditEnabled;
  storeCashEnabled;
  useStoreCredit;

  isLoading = false;
  parent;
  orderHash:string;
  phoneNumber:string;
  storeId
  isOffer = false;

  paymentOption:PaymentOption
  occasionDetail:OccasionDetail

  surcharge = 0;
  payrocUsername:string;

  storeCardEnabled:boolean;
  nmiPublicKey:string;

  loadAPI: Promise<any>;

  store:Store;
  uiSetting:any;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<PaymentMethodsBottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheet: MatBottomSheet,
    private router:Router,
    private orderService:OrderService,
    private paymentOptionService:PaymentOptionService,
    private boolService:BooleanService,
    private ref: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private occasionDetailService: OccasionDetailService
    ) {
      console.log(data);
      this.isOffer = data.isOffer;
      this.orderHash = data.orderHash;
      this.phoneNumber = data.phoneNumber;
      this.orderTotal = data.orderTotal;
      this.storeCreditBalance = data.storeCredit;
      this.cardDetails = data.cardDetails;
      this.paymentGateway = data.paymentGateway
      this.storeId = data.storeId
      this.parent = data.parent;
      this.storeCreditEnabled = true;
      this.storeCashEnabled = data.storeCashEnabled
      this.storeCardEnabled = data.storeCardEnabled
      this.occasionDetail = data.occasionDetail
      this.surcharge = data.surcharge
      this.payrocUsername = data.payrocUsername
      this.nmiPublicKey = data.nmiPublicKey;
      this.store = data.store;
      this.uiSetting = data.store.ui_settings;

      this.loadAPI = new Promise((resolve) => {
        let node = this.loadScript(data.nmiPublicKey);
        if (node) {
            node.onload = () => {
                resolve(true);
            };
        } else {
            resolve(true);
        }
      });

    }

  ngOnInit() {
    this.loadAPI.then((flag) => {
      CollectJS.configure({
        'paymentType': 'cc',
        'primaryColor': '#1e88e5',
        'buttonText': 'Add Card',
        'instructionText': 'Enter Card Info Below',
        'theme': 'material',
        'callback': function (response) {
          console.log(response);
          response.store_id = this.storeId;
          response.phone_number = this.phoneNumber;
          response.payment_gateway = this.paymentGateway;
          this.orderService.addNewCardGateway(response).subscribe(response => {
            CollectJS.closePaymentRequest();
            let cardDetails = Object.assign(new CardDetails(), response);
            this.cardDetails.push(cardDetails);
            let paymentOption = new PaymentOption(false, true, this.useStoreCredit, cardDetails, this.storeCreditBalance)
            this.isLoading = false;
            this.updateAndDismiss(paymentOption)
          })
        }.bind(this)
      });
    })

    this.paymentOptionService.current.subscribe(paymentOption => {
      if(paymentOption){
        this.paymentOption = paymentOption;
        if(paymentOption.card){
          this.selectedPaymentMethod = this.cardDetails.filter(c => c.id == paymentOption.cardDetail.id)[0]
          this.useStoreCredit = paymentOption.storeCredit
        }
        else{
          if(paymentOption.cash){
            this.useStoreCredit = paymentOption.storeCredit
          }
        }
      }
    })

    if(this.paymentGateway === 'PAYROC'){
      let options = {
        apiUsername: this.payrocUsername,
        name: 'Please add card details below:',
        description: '',
        onToken: this.submit.bind(this),
        requireEmail: false
      }

      get("https://js.itransact.com/itransact.js", () => {
        var handler = iTransact.Checkout.init(options)
        document.getElementById('payroc-button').addEventListener('click', function (event) {
          handler.render({
            panelButtonText: "Add Card",
            theme: "#467adb",
            email: 'support@labrador.ai'
          })
          event.preventDefault()
        })
      });
    }
  }

  loadScript(nmiPublicKey) {
    console.log(nmiPublicKey);

    let node = undefined;
    let isFound = false;
    const scripts = document.getElementsByTagName('script')
    for (let i = 0; i < scripts.length; ++i) {
        // Check if script is already there in html
        if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
          isFound = true;
        }
    }

    if (!isFound) {
        const dynamicScript = 'https://secure.networkmerchants.com/token/Collect.js';
        node = document.createElement('script');
        node.src = dynamicScript;
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        node.setAttribute('data-tokenization-key', nmiPublicKey);
        document.getElementsByTagName('head')[0].appendChild(node);
        return node;
    }
    return node;
}



  ngAfterViewInit(){
  }

  openCollectJsLightBox(event){
    CollectJS.startPaymentRequest(event)
  }

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    // event.preventDefault();
  }

  toggleEdit(){
    this.editCardDetails = !this.editCardDetails;
  }

  toggleToConfirmDelete(){
    this.toConfirmDelete = !this.toConfirmDelete;
  }

  confirmDeleteCard(card:CardDetails){
    this.toDelete = card;
    this.toConfirmDelete = true;
  }

  deleteCard(){
    this.orderService.deleteCard(this.toDelete).subscribe(() => {
      let index = this.cardDetails.map(c => c.id).indexOf(this.toDelete.id, 0)
      if(index > -1){
        this.cardDetails.splice(index,1);
      }
      this.toggleToConfirmDelete()
      this.ref.detectChanges();
    })
    const paymentOption = new PaymentOption(false, false, false, null, this.storeCreditBalance)
    this.updateAndDontDismiss(paymentOption)
  }

  preferCash(){
    const paymentOption = new PaymentOption(true, false, this.useStoreCredit, null, this.storeCreditBalance)
    this.updateAndDismiss(paymentOption)
  }

  preferStoreCredit(){
    const paymentOption = new PaymentOption(false, false, true, null, this.storeCreditBalance)
    this.updateAndDismiss(paymentOption)
  }

  addStoreCredit(event){
    this.paymentOption.storeCredit = event.checked;
    if(this.paymentOption.card || this.paymentOption.cash){
      this.updateAndDismiss(this.paymentOption)
    }else{
      this.snackBar.open('Store credit value is not enough to cover the total order balance, Please select another payment option.', "OK", {
        verticalPosition: 'top'})
      this.updateAndDontDismiss(this.paymentOption)
    }
  }

  preferStoreCreditAndCash(){
    const paymentOption = new PaymentOption(true, false, true, null, this.storeCreditBalance)
    this.updateAndDismiss(paymentOption)
  }

  preferCard(card:CardDetails){
    this.selectedPaymentMethod = card;
    if (card){
      const paymentOption = new PaymentOption(false, true, this.useStoreCredit, card, this.storeCreditBalance)
      this.updateAndDismiss(paymentOption)
    }
  }

  update(paymentOption){
    let payload = {
      is_cash: paymentOption.cash,
      is_card: paymentOption.card,
      is_store_credit: paymentOption.storeCredit,
      guest_payment_id: paymentOption.cardDetail ? paymentOption.cardDetail.id : null
    }
    if(this.isOffer){
      return this.orderService.updatePreferredPaymentAsCardForOffer(this.storeId, this.orderHash, payload.guest_payment_id)
    }else{
      return this.orderService.updatePreferredPayment(this.orderHash, payload)
    }
  }


  updateAndDontDismiss(paymentOption){
    this.update(paymentOption).subscribe(data => {
      this.paymentOptionService.updatePaymentOption(paymentOption);
      this.ref.detectChanges();
    }, (error) => {
      this.bottomSheetRef.dismiss(paymentOption);
      this.snackBar.dismiss();
    })
  }

  updateAndDismiss(paymentOption){
    console.log(this.occasionDetail);

    this.update(paymentOption).subscribe(data => {
      this.paymentOptionService.updatePaymentOption(paymentOption);
      this.ref.detectChanges();
      this.bottomSheetRef.dismiss({paymentOption: paymentOption,
        occasionDetail: this.occasionDetail});
      this.snackBar.dismiss();
    }, (error) => {
      console.log(error);
      this.bottomSheetRef.dismiss({paymentOption: paymentOption,
        occasionDetail: this.occasionDetail});
      this.snackBar.dismiss();
    })
  }

  addNewCard(){
    let payload = {
      storeId: this.storeId,
      phoneNumber: this.phoneNumber,
      orderHash: this.orderHash,
      paymentGateway: this.paymentGateway,
      occasionDetail: this.occasionDetail
    }
    console.log(payload);

    let paymentRef;
    if(this.paymentGateway === 'STRIPE') {
      paymentRef = this.bottomSheet.open(PaymentStripeComponent,{
        panelClass: 'payment-bottom-sheet-container',
        data: this.data
      })
    }else if(this.paymentGateway === 'CARDCONNECT'){
      paymentRef = this.bottomSheet.open(AddPaymentMethodComponent,{
        panelClass: 'payment-bottom-sheet-container',
        data: payload
      })
    }else if(this.paymentGateway === 'TRANSX'){
      this.isLoading = true
      this.orderService.getTransxHPPHash(this.storeId, this.orderHash).subscribe(data => {
        window.location.href = `https://secure.transaxgateway.com/HostedPaymentForm/HostedPaymentPage.aspx?hash=${data.hpp_hash}`
        this.isLoading = false
      })
    }else if(this.paymentGateway === 'NMIPR'){
      paymentRef = this.bottomSheet.open(NmiPaymentComponent,{
        panelClass: 'payment-bottom-sheet-container',
        data: payload
      })
    }else if(this.paymentGateway === 'WORLDNET'){
      this.isLoading = true
      this.orderService.getTransxHPPHash(this.storeId, this.orderHash).subscribe(data => {
        window.location.href = `https://secure.transaxgateway.com/HostedPaymentForm/HostedPaymentPage.aspx?hash=${data.hpp_hash}`
        this.isLoading = false
      })
    }

    if(paymentRef){
      paymentRef.afterDismissed().subscribe(cardDetail => {
        console.log(cardDetail);
        if(cardDetail){
          const paymentOption = new PaymentOption(false, true, false, cardDetail, this.storeCreditBalance)
          this.cardDetails.push(cardDetail);
          this.occasionDetailService.updateOccasionDetail(this.occasionDetail)
          this.boolService.emitFlag(new Flag('openSchedule', true))
          this.updateAndDismiss(paymentOption)
        }
      })
    }
  }

  submit(data){
    console.log(data);
    this.isLoading = true;
    this.ref.detectChanges();

    let payload = {
      'store_id': this.storeId,
      'phone_number': this.phoneNumber,
      'token': data.token,
      'payment_gateway': this.paymentGateway
    }

    this.orderService.addNewCardGateway(payload).subscribe(
      response => {
        let cardDetails = Object.assign(new CardDetails(), response);
        console.log(cardDetails);
        this.cardDetails.push(cardDetails);
        console.log(this.cardDetails);

        let paymentOption = new PaymentOption(false, true, this.useStoreCredit, cardDetails, this.storeCreditBalance)
        this.isLoading = false;
        this.updateAndDismiss(paymentOption)
      },
      error => {

      })
  }
}

