import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-nmi-payment',
  templateUrl: './nmi-payment.component.html',
  styleUrls: ['./nmi-payment.component.sass']
})
export class NmiPaymentComponent implements OnInit {

  constructor() { }

  form:FormGroup;

  ngOnInit(): void {
    this.form = new FormGroup({
      nameOnCard: new FormControl('', Validators.required),
      addtoWallet: new FormControl(true)
    })
  }

  dismiss(){}

  hasToken(){
    return false;
  }

  submit(){}
}
