import { Component, OnInit, Input } from '@angular/core';
import { Store, MenuItemGroup } from 'src/app/models/store';
import { OrdersWithPayment } from 'src/app/models/order';
import { OrderService } from 'src/app/order.service';

@Component({
  selector: 'store-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  @Input() store:Store;
  @Input() orderWithPayment:OrdersWithPayment
  @Input() storeCredit = 0;

  logo;

  constructor(private orderService:OrderService) { }

  ngOnInit() {
    console.log(this.storeCredit);
    this.orderService.getStoreLogo(this.store.id).subscribe(data => {
      this.logo = data
    })
  }


}
