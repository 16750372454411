import { Component, OnInit, Inject, ViewChild, ElementRef, ChangeDetectorRef, OnDestroy, AfterViewInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { OrderService } from '../order.service';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CardDetails } from '../models/order';
import {PaymentOption, PaymentOptionService} from '../_services/payment-option.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-payment-stripe',
  templateUrl: './payment-stripe.component.html',
  styleUrls: ['./payment-stripe.component.scss']
})
export class PaymentStripeComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private bottomSheetRef: MatBottomSheetRef<PaymentStripeComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private orderService: OrderService,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private paymentOptionService:PaymentOptionService,
    private snackBar: MatSnackBar
  ) { }

  @ViewChild('cardInfo', {static:true}) cardInfo: ElementRef;

  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;

  cardNumber:any;
  cardExpiry:any;
  cardCvc:any;
  form:FormGroup;
  storeId;
  phoneNumber;
  isLoading = false;
  orderHash;
  isOffer;

  ngOnInit(){
    console.log(this.data)
    this.form = this.fb.group({
      nameOnCard: ['', [Validators.required]]
    });

    this.storeId = this.data.storeId;
    this.phoneNumber = this.data.phoneNumber;
    this.orderHash = this.data.orderHash
    this.isOffer = this.data.isOffer;
  }

  ngAfterViewInit() {
    this.card = elements.create('card');
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy() {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  dismiss(){
    this.bottomSheetRef.dismiss();
  }

  async onSubmit(form) {
    this.isLoading = true;
    const { token, error } = await stripe.createToken(this.card);

    if (error) {
      console.log('Something is wrong:', error);
    } else {
      let nameOnCard = form.value['nameOnCard']
      this.orderService.addNewStripeCard(this.storeId, this.phoneNumber, nameOnCard,
        token.card.exp_month, token.card.exp_year, '123',
        token.id, token.card.last4, token.card.brand).subscribe(response =>{
          this.isLoading=false;
          const cardDetails = Object.assign(new CardDetails(), response);
          const paymentOption = new PaymentOption(false, true, false, cardDetails, 0)
          this.bottomSheetRef.dismiss(cardDetails);
        })
      // ...send the token to the your backend to process the charge
    }
  }
}
