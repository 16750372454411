import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Store } from '../models/store';

@Injectable()
export class PaymentGatewayService{
  endpoint = environment.orderApiBackend;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    })
  };

  constructor(private http: HttpClient){}

  getHPPUrl(store_id: number, orderHash: string) {
    const params = {
      order_hash: orderHash, store_id: store_id
    }
    return this.http.get(`${this.endpoint}/backend/gateway/worldnet/hpp-payload/`, {params: params});
  }

  getHPPUrlGA(store_id: number, orderHash: string, amount: number) {
    const params = {
      order_hash: orderHash, store_id: store_id, amount: amount
    }
    return this.http.get(`${this.endpoint}/backend/gateway/worldnet/hpp-payload/ga`, {params: params});
  }

  confirmOrder(params: any) {
    return this.http.get(`${this.endpoint}/backend/gateway/worldnet/receipt`, {params: params})
  }
}
