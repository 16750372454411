import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from 'src/app/models/store';
import { OrderService } from 'src/app/order.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-referral-verification',
  templateUrl: './referral-verification.component.html',
  styleUrls: ['./referral-verification.component.sass']
})
export class ReferralVerificationComponent {
  nextPageShowHide = '1';
  store_id: string;
  storeId: string;
  id: string;
  referralKey: string;
  queryParam1: string;
  getData: any;
  form: FormGroup;
  formVali: FormGroup;
  errorMessage: any = '';
  refereeGetsDollar: any;
  referrerGetsDollar: any;
  idGet: any;
  hash: string;
  phoneNumber: any;
  createdAt: any;
  expiresAt: any;
  redeemId: any;
  redeemedAmount: any;
  store;
  offerCatalogSharableLink;
  isLoading = true;
  ShowOPT: any;
  errorMessage1: string;
  formOTP: FormGroup;
  is_verified: false;
  errorMessage2: string;
  otpValues: string[] = ['', '', '', '', '', ''];
  nextVerifyShowHide: boolean = false;
  @ViewChild('input1') input1: ElementRef;
  @ViewChild('input2') input2: ElementRef;
  @ViewChild('input3') input3: ElementRef;
  @ViewChild('input4') input4: ElementRef;
  @ViewChild('input5') input5: ElementRef;
  @ViewChild('input6') input6: ElementRef;
  enteredOTP: string;
  phoneNumber1: any;
  FilterPromot: any;
  // hideHeader: boolean = false;
  constructor(
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
  ) {
    this.referralKey = this.route.snapshot.paramMap.get('referralKey');
    this.queryParam1 = this.route.snapshot.paramMap.get('id');
    this.storeId = this.route.snapshot.paramMap.get('storeId');
  }

  ngOnInit(): void {
    this.storeId = this.route.snapshot.paramMap.get('storeId');
    this.hash = this.route.snapshot.paramMap.get('userHash');
    this.phoneNumber = localStorage.getItem('phoneNumber')
    this.store_id = localStorage.getItem('currentStore')
    this.phoneNumber1 = localStorage.getItem('mob')
    this.FilterPromot = JSON.parse(localStorage.getItem("referee_gets"));
    this.orderService.getStoreInfo(this.storeId).subscribe(store => {
      this.store = Object.assign(new Store(), store);
      if (this.store.dynamic_offer_url) {
        this.offerCatalogSharableLink = this.store.dynamic_offer_url;
      } else {
        this.offerCatalogSharableLink = `${environment.hostName}/store/${this.store.url_slug}/offers/`
      }
      this.isLoading = false;
    });
  }

  goBack() {
    window.history.back()
  }

  focusNext(index: number) {
    if (index < this.otpValues.length) {
      const nextInput = this[`input${index + 1}`] as ElementRef;
      nextInput.nativeElement.focus();
    }
  }
  onLastInput() {
    this.enteredOTP = this.otpValues.join('');
  }

  otpVerify() {
    console.log('111111111111111', this.enteredOTP);
    if (this.enteredOTP == undefined) {
      this.errorMessage2 = 'Input filed are required.';
      return;
    }
    let keySend = {
      "store_id": this.storeId,
      "otp": this.enteredOTP,
      "phone_number": this.phoneNumber1,
    }

    this.orderService.optNumberVerify(keySend).subscribe(data => {
      if (data.is_verified) {
        this.is_verified = data.is_verified
        this.ShowOPT = false
        this.router.navigate([window.location.pathname + '/claim']);

      }
      else {
        this.errorMessage2 = 'OTP verification failed. Please try again.';;
        return;
      }
    });
  }
}
