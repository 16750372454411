import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModifierOption, ModifierGroup, Modifier } from '../../models/order';
import { Store } from 'src/app/models/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { log } from 'console';

@Component({
  selector: 'app-modifier-list',
  templateUrl: './modifier-list.component.html',
  styleUrls: ['./modifier-list.component.scss']
})
export class ModifierListComponent implements OnInit {

  @Input() modifierGroups: ModifierGroup[];

  _selectedModifiers: Modifier[];

  @Input() set selectedModifiers(m: Modifier[]) {
    if (m.length === 0) {
      this._selectedModifiers = []
    }

    if (!this._selectedModifiers) {
      this._selectedModifiers = m
    }

  }

  get selectedModifiers(): Modifier[] { return this._selectedModifiers }

  @Output() modifiersChange = new EventEmitter<Modifier[]>();

  @Output() isValid = new EventEmitter<boolean>();

  // @Input() isPizza:Boolean;

  @Input() store: Store;

  pizzaOptions = {
    "ADD": "WHOLE",
    "H1": "LEFT",
    "H2": "RIGHT"
  }
  uiSetting: any;
  upcharge_half: boolean = false;
  modifieId: any;

  modGroupCount: Map<number, number> = new Map();

  constructor(private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.uiSetting = this.store.ui_settings;
    this.checkModGroupValidity()
  }

  checkModGroupValidity() {
    console.log('[lllllllllll', this.modifierGroups);

    let requiredGroups = this.modifierGroups
      .filter(m => {
        return m.is_required &&
          m.options && m.options.length > 0 &&
          m.options.some(mod => !mod['is_pos_only']);
      })
      .map(m => ({ id: m.id, requiredQty: m.max_mods }));

    console.log('Required Groups:', requiredGroups);

    // Track selected modifiers count per group
    let selectedModifiersByGroup: { [key: number]: number } = {};

    this._selectedModifiers.forEach(m => {
      let groupId = m.modifier.modifier_group;
      let quantity = m.quantity || 1;
      selectedModifiersByGroup[groupId] = (selectedModifiersByGroup[groupId] || 0) + quantity;
    });

    console.log('Selected Modifiers by Group:', selectedModifiersByGroup);

    // Validate if each required group has at least the required quantity selected
    let isValid = requiredGroups.every(group => {
      const selectedQty = selectedModifiersByGroup[group.id] || 0;
      const requiredQty = group.requiredQty;
    
      if (requiredQty === -1) {
        return selectedQty > 0; // ✅ At least one selected for unlimited groups
      }
    
      return selectedQty >= requiredQty;
    });

    console.log('Form is valid:', isValid);
    this.isValid.emit(isValid);
  }

  onModTypeChange(event, modifier) {
    this.modifieId = modifier.id;
    if (event.value == 'H1' || event.value == 'H2') {
      this.upcharge_half = true;
    } else {
      this.upcharge_half = false;
    }

    let selectedMod = this.getSelected(modifier);
    selectedMod.mod_type = event.value;
    this.modifierGroups.forEach(group => {
      group.options.forEach(mod => {
        if (mod.id === modifier.id) {
          mod.mod_type = event.value;
        }
      });
    });
    this.modifiersChange.emit(this._selectedModifiers);
  }

  upCharge(e) {
    if (e != null) {
      const estring = e.toString();
      const cutstring = estring.replace(/\$/g, '');
      const nstring = Number(cutstring)
      const f = nstring / 2;
      return f
    }
    else {
      return
    }
  }

  onSelect(event, values) {
    console.log(event, values);
    const modFromEvent = event.options[0];
    let selectedMod = Object.assign(new ModifierOption(), modFromEvent.value)
    if (modFromEvent.selected) {
      let allSelectedMods = values.map(m => m.value);
      let modGroup = this.modifierGroups.find(p => p.id === selectedMod.modifier_group)
      // let existingGroupCount = allSelectedMods.filter(f => f.modifier_group === modGroup.id).length;
      let existingGroupCount = this.getModGroupCount(selectedMod.modifier_group);
      if (modGroup.max_mods === -1 || existingGroupCount < modGroup.max_mods) {
        const mod = new Modifier();
        mod.modifier = selectedMod;
        mod.quantity = 1;
        mod.mod_type = 'ADD';
        this._selectedModifiers.push(mod);
        this.modifiersChange.emit(this._selectedModifiers);
      } else {
        modFromEvent.selected = false
        this.snackBar.open(`Item can have ${modGroup.max_mods} '${modGroup.name.toLowerCase()}' only!`, "OK", {
          duration: 2000,
          verticalPosition: 'top'
        })
      }
    } else {
      console.log(selectedMod);
      this.modifieId = null
      this.modifierGroups[0].options.map(mod => {
        if (mod.id == selectedMod.id) {
          mod.mod_type = 'ADD';
        }
      });
      let existingCount = this.modGroupCount.get(selectedMod.modifier_group)
      if (existingCount) {
        this.modGroupCount.set(selectedMod.modifier_group, existingCount - selectedMod.quantity)
      }
      const index = this._selectedModifiers.findIndex(p => p.modifier.id === selectedMod.id);
      console.log(this._selectedModifiers[index]);
      if (index > -1) {
        this._selectedModifiers.splice(index, 1);
      }
      this.modifiersChange.emit(this._selectedModifiers);
    }
    this.checkModGroupValidity()
  }

  getModGroupCount(modGroupId) {
    if (this._selectedModifiers.length > 0) {
      let qty = this._selectedModifiers.filter(f => f.modifier.modifier_group === modGroupId).map(p => p.quantity)
      if (qty.length > 0) {
        return qty.reduce((a, b) => a + b)
      } else {
        return 0
      }
    } else {
      return this._selectedModifiers.length
    }
  }

  isSelected(modifier: ModifierOption) {
    return this._selectedModifiers.find(m => m.modifier.id === modifier.id)
  }

  getSelected(modifier: ModifierOption) {
    return this._selectedModifiers.filter(m => m.modifier.id === modifier.id)[0]
  }

  // isTopping(name:string){
  //   return name.toLowerCase().includes("topping");
  // }

  trackByIndex(index: number): any {
    return index;
  }

  updateQuantity(event, modifier) {
    let selectedMod = this.getSelected(modifier);
    selectedMod.quantity = event.quantity
    this.getModGroupCount(modifier.modifier_group)
    this.modifiersChange.emit(this._selectedModifiers);
    this.checkModGroupValidity();
  }

  getMaxModsAvailable(modGroupId: number, modGroupMax: number, modifier) {
    console.log(modifier);

    let maxModunits = modifier.modifier.max_mod_units;
    let existingModQuantity = modifier.quantity;
    let existingModGroupCount = this.getModGroupCount(modGroupId)

    console.log(maxModunits, modGroupMax, existingModGroupCount);

    let toReturn;
    if (modGroupMax > 0) {
      if (existingModGroupCount < modGroupMax) {
        if (maxModunits > 0 && maxModunits < modGroupMax) {
          console.log("max mod");

          toReturn = maxModunits - existingModQuantity;
          console.log(toReturn);

        } else if (modGroupMax > 0) {
          console.log("max mod group");
          toReturn = modGroupMax - existingModGroupCount;
        } else {
          console.log("no");
          toReturn = -1
        }
      }
    } else if (maxModunits > 0) {
      toReturn = maxModunits - existingModQuantity;
    } else {
      console.log("hadhasd");

      toReturn = -1;
    }
    console.log(toReturn);

    return toReturn;
  }

  allModIsPOS(modOption) {
    return modOption.some(modOption => !modOption.is_pos_only);
  }
}
