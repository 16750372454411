import { Component, HostListener, OnInit } from '@angular/core';
import { Store } from '../models/store';
import { OrderService } from '../order.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoyaltyPointService } from '../_services/loyalty-point.service';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-userapp-loyalty',
  templateUrl: './userapp-loyalty.component.html',
  styleUrls: ['./userapp-loyalty.component.sass']
})
export class UserappLoyaltyComponent implements OnInit {

  store;
  offerCatalogSharableLink;
  storeId: any;
  isLoading = true;
  phoneNumber: any;
  store_id: any;
  userTotalPoints: any;
  tierlist: any;
  tt: any = [];
  firstFalse: any;
  calimValue: any;
  tierPoints: any;
  calimId: any;
  heightWindowResize: any;
  hash: any;
  enrollClaim: boolean
  enrolled: boolean
  loyalty_expire_days: number;

  constructor(
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private loyaltyPointService: LoyaltyPointService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.storeId = this.route.snapshot.paramMap.get('storeId');
    this.hash = this.route.snapshot.paramMap.get('userHash');
    console.log('677777777777777776666666666',this.hash);

    this.phoneNumber = localStorage.getItem('phoneNumber')
    this.store_id = localStorage.getItem('currentStore')
    this.orderService.getStoreInfo(this.storeId).subscribe(store => {
      this.loyalty_expire_days = store['loyalty_point_expire_time']
      this.store = Object.assign(new Store(), store);
      if (this.store.dynamic_offer_url) {
        this.offerCatalogSharableLink = this.store.dynamic_offer_url;
      } else {
        this.offerCatalogSharableLink = `${environment.hostName}/store/${this.store.url_slug}/offers/`
      }
      this.singleUserPoint(this.store.id, this.phoneNumber);
      this.tierList();
      this.isLoading = false;
    });
  }

  @HostListener('window:resize', ['$event'])
  WindowResized(event?) {
    this.heightWindowResize = window.innerHeight - 150;
  }

  singleUserPoint(storeId: any, phoneNumber: any) {
    const keyData = {
      "store_id": storeId,
      "phone_number": phoneNumber,
      "status":'',
      "enroll_claim":''
    }
    this.loyaltyPointService.pointsUser(keyData).subscribe((data: any) => {
      this.userTotalPoints = Math.round(data.user_total_points * 100) / 100
      this.enrollClaim = data.enroll_claim;

    })
  }

  eventChecked(e) {
    this.enrolled = e.checked;

  }

  enrollChecked() {
    const keyData = {
      "store_id": this.store_id,
      "phone_number": this.phoneNumber,
      "status":1,
      "enroll_claim":this.enrolled
    }
    this.loyaltyPointService.pointsUser(keyData).subscribe((data: any) => {
      this.enrollClaim = data.enroll_claim;
    })
  }

  tierList() {
    if(this.store){
      this.loyaltyPointService.getTierList(this.store.id).subscribe((data: any) => {
        this.tierlist = data.data;
        for (let i = 0; i < this.tierlist.length; i++) {
          const element = this.tierlist[i];
          if (element.points <= this.userTotalPoints) {
            this.tierlist[i]['position'] = true;
            this.tierlist[i]['percent'] = 100;

          } else {
            this.tierlist[i]['position'] = false;
            // const aa = element.points - this.userTotalPoints;
            // const pp = (aa*100)/element.points;
            // this.tierlist[i]['percent'] = pp;
            this.tierlist[i]['percent'] = 0;
          }
          this.tt.push(this.tierlist[i]);
        }
        for (let j = 0; j < this.tt.length; j++) {
          if (this.tt[j].position == false) {
            const aa = this.tt[j].points - this.userTotalPoints;
            const pp = (this.userTotalPoints*100)/this.tt[j].points;
            this.tierlist[j].percent = pp;
            this.firstFalse = Math.round(aa * 100) / 100
            return
          }
        }

      });
    }
  }

  claimReward(a) {
    console.log(a)
    this.calimId = a.id;
    this.tierPoints = a.points;
    this.calimValue = a.card_value
  }

  redeemCredit() {
    if (this.userTotalPoints >= this.tierPoints) {
      const keyData = {
        "store_id": this.store_id,
        "id": this.calimId,
        "phone_number": this.phoneNumber
      }
      this.loyaltyPointService.claimUserReward(keyData).subscribe((data: any) => {
        this.snackBar.open(`Congratulations! Your reward is added in Store Credit`, "OK", {
          duration: 2000,
          verticalPosition: 'top'
        })
        this.reloadComponent();
      });
    } else {
    }
  }

  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  goBack(){
    window.history.back()
  }

}
