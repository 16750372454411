import {Component, OnInit} from '@angular/core';
import {OrdersWithPayment} from '../models/order';
import {UserActivityService} from '../_services/user-activity.service';
import {OrderService} from '../order.service';
import {ActivatedRoute, Router} from '@angular/router';
import {OrderHelpers} from '../order-summary/utils/helpers';
import {UserActivity} from '../models/user-activity';
import {Store} from '../models/store';

@Component({
  selector: 'app-user-activity',
  templateUrl: './user-activity.component.html',
  styleUrls: ['./user-activity.component.sass']
})
export class UserActivityComponent implements OnInit{

  order: OrdersWithPayment
  userActivity: UserActivity[] = [];
  hash: string;
  phoneNumber: string;
  storeId: string
  store: Store;

  constructor(private userActivityService: UserActivityService,
              private orderService: OrderService,
              private route: ActivatedRoute,
              private router: Router) {}

  ngOnInit() {
    this.hash = this.route.snapshot.paramMap.get('userHash');
    this.phoneNumber = localStorage.getItem('phoneNumber')
    this.storeId = localStorage.getItem('currentStore')
    this.orderService.getStoreInfo(this.storeId).subscribe(store => {
      this.store = Object.assign(new Store, store);
    });
    this.userActivityService.getUserActivity(this.hash).subscribe(userActivity => {
      this.userActivity = userActivity;
    });
  }

  goBack(){``
    window.history.back();
  }
}
