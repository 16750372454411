import { Store } from './store';

export class MenuGroup {
    name: string;
    parent: string;
    store: number;
}

export class MenuItem {
    id:number;
    name: string;
    display_name:string;
    portion: string;
    description: string;
    base_price: number;
    pos_name: string;
    menu_item_group: number;
    store: number;
    is_available:boolean;
    available_qty:number;
    is_pos_only:boolean;
    is_taxable:boolean;
}

export class ModifierGroup {
    id:number;
    name: string;
    max_mods: number;
    is_required: boolean;
    store: number;
    options: ModifierOption[]
}

export class ModifierOption {
    id:number;
    name: string;
    modifier_group: number;
    upcharge: number;
    store: number;
    is_available:boolean;
    has_quantity:boolean;
    has_half_mod:boolean;
    max_mod_units:number;
    half_mod_upcharge: any;
    fixed_price:boolean;
    mod_type:string;

    getUpcharge(quantity): number{
      if (this.fixed_price) {
        return +this.upcharge * 1;
      } else {
        return +this.upcharge * quantity;
      }
    }

    getUpcharge1(quantity): number{
      if (this.fixed_price) {
        return +this.half_mod_upcharge * 1;
      } else {
        return +this.half_mod_upcharge * quantity;
      }
    }
  }

export class Modifier {
    modifier: ModifierOption;
    modifier_raw: string;
    quantity:number;
    mod_type:string;
    price:number;
    max_mod_units:number;
    has_half_mod:boolean;
    has_quantity:boolean;
    ts_submitted:boolean;

    getUpcharge(): number{
      this.modifier = Object.assign(new ModifierOption(), this.modifier)
      if (!this.modifier || this.mod_type.toLowerCase() === 'no' || this.mod_type.toLowerCase() === 'remove'){
        return 0;
      } else {
        if (this.mod_type == "H1" || this.mod_type == "H2") {
          if (this.modifier.half_mod_upcharge != null) {
            let upcharge = this.modifier.getUpcharge1(this.quantity);
            return upcharge
          } else {
            let upcharge = this.modifier.getUpcharge(this.quantity);
            return upcharge
          }
      } else {
        let upcharge = this.modifier.getUpcharge(this.quantity);
        return upcharge
      }
    }
  }
}

export class Order {
  id:number;
  bot_order: number;
  quantity: number;
  portion: string;
  menu_item: MenuItem;
  menu_item_raw: string;
  special_request: string;
  ts_submitted:boolean;
  sale_price: number;
  modifiers: Modifier[];

  getItemTotal(){
    this.menu_item = Object.assign(new MenuItem(), this.menu_item);
    let itemTotal = 0
    itemTotal = +this.menu_item.base_price * this.quantity;
    for(let mod of this.modifiers) {
      itemTotal = itemTotal + (mod.price * (mod.modifier.fixed_price ? 1 : mod.quantity));
    }
    return itemTotal;
  }

  getAvailableQty(){
    if(this.menu_item.available_qty !== null){
      return this.menu_item.available_qty;
    }else{
      return 500;
    }
  }
}

export class MiscItem{
  quantity:number;
  item_name:string;
  price:number;
  ts_submitted:boolean;
}

export class Payment{
  auth_number: string;
  date:Date;
  sub_total:number;
  tax: number;
  tip:number;
  total:number;
  type:string;
  delivery_charge:number;
  store_credit:number;
  service_charge:number;
  discount:number;
  promotional_discount:number;
  surcharge:number;
  cash_due_total:number;
}

export class EditModifierOptions{
  parsed_menu_item: Order;

  modifier_groups:Map<string, ModifierOption[]>;
}

export class ModifierGroups{
  menu_item: MenuItem;
  modifier_groups:ModifierGroup[];
}

export class BotOrder {
  id: number;
  phone_number: string;
  store: number;
  account: number;
  time: string;
  time_raw: string;
  occasion: string;
  occasion_raw: string;
  occasion_schedule:string;
  delivery_address:string;
  order: string;
  special_request: string;
  order_hash: string;
  dine_in_table_number: string;
  is_submitted: boolean;
  is_temp:boolean;
  is_parsed:boolean;
  order_counter:string;
  phone_number_verified:boolean;
  encoded_id:string;
  curbside_vehicle_id:string;
  curbside_drop_off:boolean;
  order_name:string;
  failure_reason:string;
  is_tab_open:boolean;
  is_expired: boolean;
  promo_code: string;

  created_at: Date;
  updated_at: Date;
  submitted_at:Date;
  tip_percent:number;
  online_service_charge:any;
  view_notified: boolean;
  is_cancelled: any;
  ready_notified: boolean;
  payment_type: any;
  delivery_status:any
  delivery_status_doordash:string
}
export class OrdersWithPayment{
  bot_order:BotOrder;
  store:Store;
  items:Order[];
  misc_items: MiscItem[];
  payment: Payment;

  applyStoreCredit(amount){
    return this.payment.store_credit < this.payment.total ? this.payment.total : this.payment.store_credit - this.payment.total
  }

  updateTip(tipPercentage:number){
    this.bot_order.tip_percent = tipPercentage;
    this.payment.tip = +(this.payment.sub_total * (+tipPercentage / 100)).toFixed(2)
    this.payment.total = +(this.payment.sub_total + this.payment.tax + this.payment.tip).toFixed(2);
  }

  updateTipCustom(tip:number){
    this.payment.tip = +(tip).toFixed(2)
    this.payment.total = +(this.payment.sub_total + this.payment.tax + this.payment.tip).toFixed(2);
  }

  getItemCount(){
    return this.items.reduce((sum,item) => sum + item.quantity, 0)
  }

  isValidOrder(){
    if(this.bot_order.occasion === 'DELIVERY'){
      return this.payment.type !== 'STORE_CREDIT' ? (this.payment.total + this.payment.store_credit) >= this.store.delivery_minimum : true
    }else if(this.bot_order.occasion === 'TABLESIDE'){
      return this.bot_order.dine_in_table_number ? true : false;
    }else if(this.bot_order.occasion === 'CURBSIDE'){
      return this.bot_order.curbside_vehicle_id ? true : false;
    }else{
      return true;
    }
  }

  getOriginalTotal(){
    return this.payment.tip + this.payment.sub_total + this.payment.tax + this.payment.delivery_charge
  }

  getTipPercent(){
    return +this.bot_order.tip_percent;
  }

  getTipPercentOptions(){
    return this.store.tip_percent_option.sort((a,b) => a.percent - b.percent);
  }

  getTableSideItemCount(){
    return this.items.filter(i => !i.ts_submitted).reduce((sum,item) => sum + item.quantity, 0)
  }

  isTabOpen(){
    return this.bot_order.is_tab_open;
  }

  getOccasion(){
    return this.bot_order.occasion;
  }

  getOccasionDetail(){
    return new OccasionDetail(this.bot_order.occasion, this.bot_order.time,
      this.bot_order.occasion_schedule,this.bot_order.dine_in_table_number,
      this.bot_order.delivery_address, this.bot_order.curbside_vehicle_id)
  }

  setOccasionDetail(occasionDetail:OccasionDetail){
    console.log(occasionDetail);

    this.bot_order.occasion = occasionDetail.occasion;
    this.bot_order.time = occasionDetail.time;
    this.bot_order.occasion_schedule = occasionDetail.occasion_schedule;
    this.bot_order.dine_in_table_number = occasionDetail.dine_in_table_number;
    this.bot_order.delivery_address = occasionDetail.delivery_address;
    this.bot_order.curbside_vehicle_id = occasionDetail.curbside_vehicle_id;
  }

  getTableNumber(){
    return this.bot_order.dine_in_table_number.trim();
  }
}

export class MenuItemGroup{
  id:number;
  name:string;
  description:string;
  starting_price:number;
  store:number;
  menu_group:MenuGroup;
}

export class CardDetails{
    id:number;
    name_on_card:string;
    card_type: string;
    phone_number:string;
    token:string;
    expiry:string;
    cvc:number;
    store:number;
    expiryMonth:string;
    expiryYear:string;
    last_4:string;
    gateway:string;
    is_deleted: boolean;
    updated_at: Date;
    created_at: Date;
    zip_code: number

    getExpMonth(){
      return this.expiry.length === 5 ? `0${this.expiry.slice(0, 1)}` : this.expiry.slice(0, 2);
    }

    getExpYear(){
      return this.expiry.slice(-2);
    }

    hasExpired(){
      const today = new Date();
      const month = this.expiry.slice(0, 2);
      const year = `20${this.expiry.slice(-2)}`;

      return today.getMonth() > +month && today.getFullYear() >= +year;
    }
  }

export class PaymentMethods{
  cards:Map<string, CardDetails[]>;
}

export class HostedMenu{
  upload_file:string;
}

export class OccasionDetail{
  occasion:string;
  time:string;
  occasion_schedule:string;
  delivery_address:string;
  dine_in_table_number:string;
  curbside_vehicle_id:string;
  location_selected:boolean;

  constructor(occasion, time='NOW', schedule=null,
    tableId=null, address=null, vehicleId=null){
    this.occasion = occasion
    this.time = time
    this.occasion_schedule = schedule
    this.dine_in_table_number = tableId
    this.delivery_address = address
    this.curbside_vehicle_id = vehicleId
  }

  isEmpty(value){
    return (typeof value === "undefined" || value === null || value === "");
  }

  isValid(){
    let valid = false;
      switch(this.occasion){
        case 'PICKUP':
          valid = true
          break;
        case 'DELIVERY':
          valid = !this.isEmpty(this.delivery_address) && this.location_selected
          break;
        case 'CURBSIDE':
          valid = !this.isEmpty(this.curbside_vehicle_id)
          break;
        case 'DINE-IN':
          valid = !this.isEmpty(this.dine_in_table_number)
          break;
        case 'TABLESIDE':
          valid = !this.isEmpty(this.dine_in_table_number)
          break;
      }

      if(this.time === 'LATER'){
        return valid && !this.isEmpty(this.occasion_schedule)
      }else{
        return valid;
      }
  }
}
