import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Store } from '../models/store';

export class Flag{
  key: string;
  value: boolean;
  constructor(key:string, value:boolean){
    this.key = key;
    this.value = value;
  }
}

@Injectable()
export class BooleanService{

  private source = new BehaviorSubject<Flag>(null);
  current = this.source.asObservable();

  constructor(){}

  emitFlag(flag: Flag) {
    this.source.next(flag);
  }

}
