import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, OnChanges, NgZone, OnDestroy } from '@angular/core';
import { OrdersWithPayment, BotOrder, OccasionDetail } from 'src/app/models/order';
import { ScheduleBottomSheetComponent } from '../schedule-bottom-sheet/schedule-bottom-sheet-component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Store } from 'src/app/models/store';
import { OrderService } from 'src/app/order.service';
import { BooleanService } from 'src/app/_services/boolean.service';
import { PaymentOption } from 'src/app/_services/payment-option.service';
import { OccasionDetailService } from 'src/app/_services/occasion-detail.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-order-summary-occasion',
  templateUrl: './occasion.component.html',
  styleUrls: ['./occasion.component.scss']
})
export class OccasionComponent implements OnInit, OnDestroy{
  @Output() occasionChange = new EventEmitter<any>()
  @Input() hasPlacedTablesideOrder;
  @Input() orderWithPayment:OrdersWithPayment;
  @Input() paymentOption:PaymentOption;

  store:Store;
  occasionDetail:OccasionDetail;

  occasionDetailSub:Subscription;
  boolSub:Subscription;

  occasionChoice = [
    ['PICKUP', 'Pickup'],
    ['CURBSIDE', 'Curbside'],
    ['DELIVERY', 'Delivery'],
    ['DINE-IN', 'Dine-In'],
    ['TABLESIDE', 'Tableside']
  ]

  constructor(
    private scheduleBottomSheet:MatBottomSheet,
    private boolService:BooleanService,
    private occasionDetailService: OccasionDetailService
  ) { }

  ngOnInit() {
    this.occasionDetailSub = this.occasionDetailService.current.subscribe(occasionDetail => {
      if(occasionDetail){
        this.occasionDetail = occasionDetail;
      }
    })

    if(this.orderWithPayment){
      this.store = this.orderWithPayment.store;
      this.occasionDetail = this.orderWithPayment.getOccasionDetail();
    }

    this.boolSub = this.boolService.current.subscribe(data => {
      if(data && data.key === 'openSchedule' && data.value){
        this.openBottomSheet(this.orderWithPayment?.bot_order.occasion);
        data.value = false;
        this.boolService.emitFlag(data);
      }
    })
  }

  ngOnDestroy(){
    this.occasionDetailSub.unsubscribe();
    this.boolSub.unsubscribe();
  }

  openBottomSheet(event): void {
    this.occasionDetail.occasion = event;
    if(event === 'TABLESIDE'){
      this.occasionDetail.time = 'NOW';
    }

    let bsRef = this.scheduleBottomSheet.open(ScheduleBottomSheetComponent, {
      panelClass: 'schedule-bottom-sheet-container',
      disableClose: localStorage.getItem(`${this.orderWithPayment.bot_order.order_hash}_occasionInitial`) !== 'false',
      data: {
        orderWithPayment: this.orderWithPayment,
        paymentOption: this.paymentOption,
        occasionDetail: this.occasionDetail,
        hasPlacedTablesideOrder: this.hasPlacedTablesideOrder,
      }
    });

    bsRef.backdropClick().subscribe(event => {
      this.occasionDetail = this.orderWithPayment.getOccasionDetail()
    })

    bsRef.afterDismissed().subscribe(occasionDetail => {
      if(occasionDetail !== 'cancel'){
        this.occasionDetailService.updateOccasionDetail(occasionDetail);
      }else{
        this.occasionDetail = this.orderWithPayment.getOccasionDetail()
      }
    })
  }
}
