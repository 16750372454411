import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-arrived-dialog',
  templateUrl: './arrived-dialog.component.html',
  styleUrls: ['./arrived-dialog.component.scss']
})

export class ArrivedDialogComponent {

  message;
  confirmText;
  parking;
  name;
  show;
  form: FormGroup;
  spotField: any;

  constructor(
    public dialogRef: MatDialogRef<ArrivedDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data) {
      this.confirmText = data.confirmText;
      this.message = data.message;
      this.parking = data.parking;
      this.spotField = data.spot_field;
    }

  ngOnInit() {
    if (this.spotField) {
      this.form = this.formBuilder.group({
        name: ['', Validators.required]
      });
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}
