import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class FaviconService {
  setFavicon(iconUrl: string): void {
    const head = document.getElementsByTagName('head')[0];
    const existingIcon = document.getElementById('dynamic-favicon');

    if (existingIcon) {
      head.removeChild(existingIcon);
    }

    const newIcon = document.createElement('link');
    newIcon.id = 'dynamic-favicon';
    newIcon.rel = 'icon';
    newIcon.href = iconUrl;
    head.appendChild(newIcon);
  }
}
