export const environment = {
  production: true,
  firebase:{
    apiKey: "AIzaSyBKHYGsT9uTznTz5FOFxqVxjXeXyyUxkH0",
    authDomain: "labrador-frontend.firebaseapp.com",
    databaseURL: "https://labrador-frontend.firebaseio.com",
    projectId: "labrador-frontend",
    storageBucket: "labrador-frontend.appspot.com",
    messagingSenderId: "917402468037"
  },
  websocketHost:'wss://backend.text-order.com',
  orderApiBackend: "https://backend.text-order.com",
  // orderApiBackend: 'https://order-api-backend-628090102143.us-east1.run.app',
  accessToken: "da6ff0a478066427d559b7a3dbe1f26463ad283a",
  iTokenUrl: "https://fts-uat.cardconnect.com",
  hostName: "https://order.text-order.com",
  referralPinvrf: "https://referstage.pinvrf.com"
};
