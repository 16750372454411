import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { webSocket } from 'rxjs/webSocket';
import { StoreService } from './store.service';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Store } from '../models/store';

@Injectable({
  providedIn: 'root'
})
export class OrderSocketService {

  private orderStatusSocket;
  public orderStatusSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public orderStatusSocketStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public frontFacelogin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);


  private isSocketActive = true;
  isRetrying = false;

  store: Store;

  constructor(storeService: StoreService) {
    storeService.current.subscribe(store => {
      if(store){
        console.log('store2222222222222222',store);
        
        this.store = store;
        // this.initOrderStatus();
      }
    });
  }

  retry(){
    console.log('retrying socket connection', this.isSocketActive);
    this.isRetrying = true;
    interval(5000)
    .pipe(takeWhile(() => !this.isSocketActive))
    .subscribe(() => {
      this.initOrderStatus();
    });
  }

  initOrderStatus(){
    this.orderStatusSocket = webSocket(`${environment.websocketHost}/ws/order/${this.store.id}/status/`);
    this.orderStatusSocket.subscribe(
    (order) => {
      this.isSocketActive = true;
      this.isRetrying = false;
      this.orderStatusSubject.next(order);
      this.orderStatusSocketStatus.next(true);
      this.frontFacelogin.next(true);
    },
    (err: any) => {
      console.log(err);
      this.isSocketActive = false;
      this.orderStatusSocketStatus.next(false);
      this.frontFacelogin.next(false);
      console.log('attempting to retry');
      if(!this.isRetrying){
        // this.retry();
      }
    });
  }
}
