import { Component } from '@angular/core';
import {PaymentGatewayService} from "../../_services/payment-gateway.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.sass']
})
export class ReceiptComponent {
  isLoading = true;

  constructor(private pgService: PaymentGatewayService,
              private route: ActivatedRoute,
              private router: Router) {
    this.route.queryParams.subscribe(params => {
      this.pgService.confirmOrder(params).subscribe(result => {
        this.router.navigate(['order', result['order_hash'], 'status', 'success']);
      }, err => {
        this.router.navigate(['order', err['order_hash'], 'status', 'success']);
        })
    });
  }

}
