import { Component } from '@angular/core';
import {Store} from '../../../models/store';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {OrderService} from '../../../order.service';
import {NgNavigatorShareService} from 'ng-navigator-share';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-send-gift-card',
  templateUrl: './send-gift-card.component.html',
  styleUrls: ['./send-gift-card.component.sass']
})
export class SendGiftCardComponent {

  store: Store;
  hash: string;
  phoneNumber: string;
  offerCatalogSharableLink: string;
  storeId: string;
  isLoading: boolean = false;
  myOffers: any[];
  phone_number_verified: boolean = false;
  ngNavigatorShareService: NgNavigatorShareService;
  constructor(private route: ActivatedRoute,
              private orderService: OrderService,
              ngNavigatorShareService: NgNavigatorShareService,
              private snackBar: MatSnackBar,) {

    this.ngNavigatorShareService = ngNavigatorShareService;

    this.hash = this.route.snapshot.paramMap.get('hash');
    this.phoneNumber = localStorage.getItem('phoneNumber');
    this.storeId = this.route.snapshot.paramMap.get('storeId');

    this.orderService.getStoreInfo(this.storeId).subscribe(store => {
      this.store = Object.assign(new Store(), store);
    });

    if(this.hash){
      this.orderService.getMyOffersWithHash(this.storeId, this.hash).subscribe(myOffers => {
        this.myOffers = myOffers
        this.phone_number_verified = true;
        this.isLoading = false;
      });
    }else{
      if(localStorage.getItem('phoneNumber') !== null){
        this.phone_number_verified = true;
        this.phoneNumber = localStorage.getItem('phoneNumber')
      }

      if(this.phone_number_verified){
        this.orderService.getMyOffers(this.phoneNumber).subscribe(myOffers => {
          this.myOffers = myOffers
          this.isLoading = false;
        })
      }
    }
  }

  confirm(offer){
    console.log(offer);
    let url = `${environment.hostName}/gift/${offer.offer_order.offer_order_id}/`
    if (!this.ngNavigatorShareService.canShare()) {
      this.copyMessage(url);
      return;
    }

    if(confirm('Once you gift this card and it’s claimed, you will no longer be able to use this card!')){
      this.ngNavigatorShareService.share({
        title: 'I got this for you!',
        text: 'I got this for you!\n\n',
        url: url
      }).then( (response) => {
        console.log(response);
      })
        .catch( (error) => {
          console.log(error);
          window.location.reload();
        });
    }
  }

  copyMessage(val: string){
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.snackBar.open('Link copied to clipboard - share link with the new owner!', "OK", {
      duration: 5000,
      verticalPosition: 'top'})
  }


}
