import { Component, OnInit } from '@angular/core';
import { Store } from '../models/store';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../order.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-offer-v2',
  templateUrl: './offer-v2.component.html',
  styleUrls: ['./offer-v2.component.sass']
})
export class OfferV2Component implements OnInit {

  storeId:string;
  offerOrderId:string;
  store:Store;
  offers:any[];
  navigationSubscription;
  offerCatalogSharableLink;
  isLoading = true;
  offerOrder;
  userHash:string;
  phoneNumber: string;

  orderId:string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orderService:OrderService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.orderId = this.route.snapshot.paramMap.get('orderId');
    this.storeId = this.route.snapshot.paramMap.get('storeId');
    this.orderService.getStoreInfo(this.storeId).subscribe(store => {
      this.store = Object.assign(new Store(), store);
      this.orderService.getOffersV2(this.storeId).subscribe(offers => {
        this.offers = offers.data;
      })
      this.isLoading = false;
    });

    this.userHash = localStorage.getItem('userHash')
    this.phoneNumber = localStorage.getItem('phoneNumber')
  }

  copyMessage(val: string){
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.snackBar.open(`Promo code '${val}' copied!`, "OK", {
      duration: 2000,
      verticalPosition: 'top'
    })
  }

  useOffer(offer){
    if(this.orderId){
      this.orderService.applyPromoCode(this.orderId, offer.promo_code).subscribe(data => {
        this.router.navigate(['order', this.orderId])
      })
    }else{
      this.orderService.createTempOrderWithPhone(this.store.url_slug, this.phoneNumber, offer.promo_code).subscribe(response => {
        this.router.navigate(['order', response['order_hash'], 'store', response['url_slug']])
      })
    }

  }

  goBack(){}

}
