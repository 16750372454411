import { Component, OnInit, Inject, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { CardDetails, OrdersWithPayment, Order, OccasionDetail, BotOrder } from 'src/app/models/order';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { PaymentMethodsBottomSheetComponent } from '../payment-methods-bottom-sheet/payment-methods-bottom-sheet-component';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import * as moment from 'moment';
import { extendMoment } from 'moment-range';
import { OrderService } from '../../order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, Table } from 'src/app/models/store';
import PlaceResult = google.maps.places.PlaceResult;
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { PaymentOption, PaymentOptionService } from 'src/app/_services/payment-option.service';
import { BooleanService } from 'src/app/_services/boolean.service';
import { Subscription, Observable, of } from 'rxjs';
import { OccasionDetailService } from 'src/app/_services/occasion-detail.service';

const { range } = extendMoment(moment);

@Component({
  selector: 'schedule-bottom-sheet',
  templateUrl: './schedule-bottom-sheet.html',
  styleUrls: ['./schedule-bottom-sheet.scss']
})
export class ScheduleBottomSheetComponent implements OnInit, OnDestroy {

  occasion;
  orderHash: string;
  occasionTime: string;
  occasionSchedule;
  scheduledDate;
  scheduledTime;
  dateRange;
  timeRange;
  deliveryAddress;
  dineInTableId;
  curbsideVehicleId;
  store: Store;
  latitude;
  longitude;
  hasPlacedTablesideOrder;
  locationSelected = false;
  // Payment Bottom Sheet
  isLoading = true;
  guestCardDetails: CardDetails[];
  storeCredit = 0;
  paymentGateway;
  storeId: string;
  phoneNumber: string;
  orderWithPayment: OrdersWithPayment;
  selectedPaymentMethod;

  @ViewChild('deliveryAddressInput', { static: false }) deliveryAddressInput: ElementRef;

  paymentOption: PaymentOption;
  paymentServiceSub: Subscription;
  updatedDate;
  occasionDetail: OccasionDetail;

  initial = true;

  tables: Table[]
  filteredTables

  serviceAreas;
  serviceAreaLabel: string;


  constructor(
    private bottomSheet: MatBottomSheet,
    private bottomSheetRef: MatBottomSheetRef<ScheduleBottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private orderService: OrderService,
    private paymentOptionService: PaymentOptionService,
    private ref: ChangeDetectorRef,
    private occasionDetailService: OccasionDetailService) {
    console.log(data);

    let botOrder: BotOrder = data.orderWithPayment.bot_order;
    let store = data.orderWithPayment.store;

    this.occasionDetail = data.occasionDetail || data.orderWithPayment.getOccasionDetail()
    this.orderHash = botOrder.order_hash;
    this.store = store
    this.hasPlacedTablesideOrder = data.hasPlacedTablesideOrder;
    this.orderWithPayment = data.orderWithPayment;
    this.paymentOption = data.paymentOption
    this.buildTimeRange()
  }

  ngOnInit() {
    this.paymentServiceSub = this.paymentOptionService.current.subscribe(paymentOption => {
      console.log(paymentOption);
      if (paymentOption) {
        this.paymentOption = paymentOption;
      }
      this.ref.detectChanges()
    })

    this.orderService.getTablesideTables(this.store.id).subscribe(tables => {
      this.tables = tables;
      this.filteredTables = tables;
    })

    this.orderService.getServiceAreas(this.store.id).subscribe(data => {
      this.serviceAreas = data
      if (data.service_type === 'RADIUS') {
        this.serviceAreaLabel = `Delivery Radius: ${data.radius} Miles`
      } else {
        this.serviceAreaLabel = `Delivering ${data.areas.map(p => p.name).join(", ")}`
      }

    })

    this.initial = localStorage.getItem(`${this.orderHash}_occasionInitial`) !== 'false'
    if (localStorage.getItem('curbside_vehicle_id')) {
      this.occasionDetail.curbside_vehicle_id = localStorage.getItem('curbside_vehicle_id')
    }
    this.isLoading = true
  }

  filterTables(event) {
    this.filteredTables = this.tables.filter(table => table.table_name.toLowerCase().indexOf(event.target.value.toLowerCase()) === 0);
  }

  ngOnDestroy() {
    this.paymentServiceSub.unsubscribe();
  }

  checkOccasion() {
    console.log(this.occasionDetail);

  }

  onTimeChange(value: string) {
    this.occasionDetail.time = value;
    this.ref.detectChanges(); // Manually trigger change detection
  }

  buildTimeRange(date = '') {
    var start = null
    let selectedDate;
    let isToday = true;
    if (date) {
      const currentYear = moment().year();
      const dateWithYear = `${date}, ${currentYear}`;
      selectedDate = moment(dateWithYear, 'ddd, MMM DD, YYYY').format('YYYY-MM-DD');
      const todayDate = moment().format('YYYY-MM-DD');
      isToday = selectedDate === todayDate;
    }

    start = isToday ? moment().toDate() : moment(selectedDate).startOf('day').toDate();
    var leadTime = this.store.getLeadTime(this.occasionDetail.occasion);

    console.log('Start', start);

    let special_hour = this.store.getSpecialHoursOn(start);
    let day_config = special_hour ? special_hour : this.store.getWorkingConfigOn(start);
    if (day_config && moment(start).isAfter(moment(day_config.from_hour, 'HH:mm'))) {
      if (!isToday) {
        start.setHours(day_config.from_hour)
        start.setMinutes(day_config.from_min)
      }
    } else {
      start.setHours(day_config.from_hour)
      start.setMinutes(day_config.from_min)
    }

    start = moment(start).add(leadTime, 'minutes')
    const remainder = 15 - (start.minute() % 15);
    start = moment(start).add(remainder, "minutes")

    this.occasionSchedule = start
    this.scheduledDate = this.occasionSchedule.format("ddd, MMM D");
    this.scheduledTime = this.occasionSchedule.format("hh:mm A");

    let startDate;
    let startDateRange;
    let endDate;
    let range1;

    if (isToday) {
      startDate = new Date()
      endDate = moment(startDate).add(7, 'days');
      range1 = range([startDate, endDate]);
    } else {
      startDate = moment(selectedDate).toDate();
      startDateRange = new Date()
      endDate = moment(startDateRange).add(7, 'days');
      range1 = range([startDateRange, endDate]);
    }
    this.dateRange = Array.from(range1.by('day'));

    this.OnDateChange(moment(startDate).format('ddd, MMM DD'), leadTime);
  }

  OnDateChange(date, leadTime) {
    const start = moment(date, 'ddd, MMM DD').toDate()
    const times = 24 * 4; // 24 hours * 15 mins in an hour
    this.timeRange = new Array(times)

    for (let i = 0; i < times; i++) {
      const toPrint = moment(start)
        .add(15 * i, 'minutes')
      if (this.store.is_open_on(toPrint.toDate(), leadTime)) {
        const formattedTime = toPrint.format('HH:mm a');
        if (moment(formattedTime, 'HH:mm a').isSameOrAfter(moment(this.scheduledTime, 'HH:mm a'))) {
          this.timeRange[i] = toPrint

        }
      }
    }
    this.timeRange = this.timeRange.filter(Boolean)
  }

  haversineDistance(mk1, mk2) {
    var R = 3958.8; // Radius of the Earth in miles
    var rlat1 = mk1.lat() * (Math.PI / 180); // Convert degrees to radians
    var rlat2 = mk2.lat() * (Math.PI / 180); // Convert degrees to radians
    var difflat = rlat2 - rlat1; // Radian difference (latitudes)
    var difflon = (mk2.lng() - mk1.lng()) * (Math.PI / 180); // Radian difference (longitudes)

    var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
    return d;
  }

  trigerSubmit(event: any) {
    this.onSubmit();
  }

  closeSheet() {
    this.bottomSheetRef.dismiss("cancel");
  }

  onSubmit() {
    this.updatedDate = moment(this.scheduledDate + " " + this.scheduledTime, "ddd, MMM DD hh:mm A")
    this.occasionDetail.occasion_schedule = this.updatedDate
    if (this.occasionDetail.curbside_vehicle_id) {
      localStorage.setItem('curbside_vehicle_id', this.occasionDetail.curbside_vehicle_id)
    }
    this.orderService.updateOccasionSchedule(this.orderHash, this.occasionDetail).subscribe(data => {
      this.orderWithPayment.setOccasionDetail(this.occasionDetail);
      this.occasionDetailService.updateOccasionDetail(this.occasionDetail);
      localStorage.setItem(`${this.orderHash}_occasionInitial`, 'false')
      this.bottomSheetRef.dismiss(this.occasionDetail);
    })
  }

  onAddressSelected(result: PlaceResult) {
    console.log('onAddressSelected: ', result);
  }

  getAc(v) {
    return { 'long_name': v.long_name, 'short_name': v.short_name, 'type': v.types[0] }
  }

  onAutocompleteSelected(result: PlaceResult) {
    console.log(result);

    if (this.store.gmb_location_id) {
      if (this.serviceAreas.service_type === 'RADIUS') {
        console.log(this.store.gmb_location_id);

        let geocoder = new google.maps.Geocoder;
        geocoder.geocode({ 'placeId': this.store.gmb_location_id }, (storeResult, status) => {
          if (storeResult.length > 0) {
            let storeLocation = storeResult[0].geometry.location
            let deliveryLocation = result.geometry.location
            let deliveryDistance = this.haversineDistance(storeLocation, deliveryLocation)
            let deliveryRadius = +this.serviceAreas.radius

            console.log(deliveryDistance);

            if (deliveryDistance <= deliveryRadius) {
              this.deliveryAddress = result.formatted_address;
              this.occasionDetail.delivery_address = result.formatted_address
              this.occasionDetail.location_selected = true
              this.orderService.updateDeliveryFee(this.orderWithPayment.bot_order.id,
                this.orderWithPayment.store.delivery_charge).subscribe(data => {
                  this.ref.detectChanges()
                })
            } else {
              alert(`Your address '${result.formatted_address}' is out of range for delivery.`)
              this.locationSelected = false;
              this.deliveryAddress = undefined
              this.occasionDetail.delivery_address = undefined
              this.occasionDetail.location_selected = false
              this.deliveryAddressInput.nativeElement.focus()
            }
          }
        })
        console.log('onAutocompleteSelected: ', result);
      } else {
        let areas = this.serviceAreas.areas
        let levels = result.address_components.map(ac => this.getAc(ac));
        console.log(areas);
        this.occasionDetail.location_selected = false;
        areas.forEach(area => {
          let matches = area.levels.map(al => {
            let gLevel = this.getAreaType(al.type, levels)
            return gLevel && gLevel.long_name === al.long_name
          })
          if (matches.every(b => b) && !this.occasionDetail.location_selected) {
            this.deliveryAddress = result.formatted_address;
            this.occasionDetail.delivery_address = result.formatted_address
            this.occasionDetail.location_selected = true
            this.orderService.updateDeliveryFee(this.orderWithPayment.bot_order.id, area.charge).subscribe(data => {
              this.ref.detectChanges()
            })
          }
        });

        if (!this.occasionDetail.location_selected) {
          alert(`Your address '${result.formatted_address}' is out of range for delivery.`)
          this.locationSelected = false;
          this.deliveryAddress = undefined
          this.occasionDetail.delivery_address = undefined
          this.occasionDetail.location_selected = false
          this.deliveryAddressInput.nativeElement.focus()
        }
      }
    } else {
      this.deliveryAddress = result.formatted_address;
      this.occasionDetail.delivery_address = result.formatted_address
    }
  }



  checkEq(left, right) {
    return left == right
  }

  getAreaType(key, obj) {
    return obj.find(a => a.type === key)
  }

  getPlainPhoneNumber() {
    if (this.phoneNumber) {
      let ph = this.phoneNumber.replace(/[\s()-]/g, '')
      return '+1' + ph.substring(0, 10)
    }
    return "";
  }

  openScheduleBottomSheet(orderWithPayment, data) {
    this.bottomSheet.open(ScheduleBottomSheetComponent, {
      panelClass: 'schedule-bottom-sheet-container',
      data: {
        orderWithPayment: orderWithPayment,
        paymentOption: data.paymentOption,
        occasionDetail: data.occasionDetail
      }
    })
  }

  addCard() {
    this.isLoading = true;
    this.orderService.getCards(this.store.id,
      this.orderWithPayment.bot_order.phone_number,
      this.orderWithPayment.store.payment_gateway).subscribe(data => {
        this.guestCardDetails = data['cards'].map(o => Object.assign(new CardDetails(), o));
        this.storeCredit = data['store_credit']
        this.isLoading = false;
      },
        (error) => {
          this.guestCardDetails = []
          this.storeCredit = error.error.store_credit
          this.isLoading = false
        }).add(() => {
          this.bottomSheet.open(PaymentMethodsBottomSheetComponent, {
            panelClass: 'payment-bottom-sheet-container',
            disableClose: !this.paymentOption.hasPaymentOption(),
            data: {
              orderHash: this.orderWithPayment.bot_order.order_hash,
              phoneNumber: this.orderWithPayment.bot_order.phone_number,
              orderTotal: this.orderWithPayment.payment.total,
              storeCredit: this.storeCredit,
              cardDetails: this.guestCardDetails,
              paymentGateway: this.orderWithPayment.store.payment_gateway,
              storeId: this.orderWithPayment.store.id,
              parent: this,
              storeCreditEnabled: true,
              storeCashEnabled: this.store.enable_cash_payment,
              paymentOption: this.paymentOption,
              occasionDetail: this.occasionDetail,
              surcharge: this.store.surcharge_percent,
              payrocUsername: this.store.payroc_username,
              storeCardEnabled: this.store.has_card_payment,
              store: this.store
            }
          }).afterDismissed().subscribe(data => {
            console.log(data);
            if (data) {
              this.paymentOption = data.paymentOption
              this.occasionDetail = data.occasionDetail
              this.openScheduleBottomSheet(this.orderWithPayment, data)
            }
          });
        });
  }
}
