import { Component, OnInit, Inject, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { OrderService } from '../order.service';
import { Router } from '@angular/router';
import { CardDetails } from '../models/order';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef, MatBottomSheet } from '@angular/material/bottom-sheet';
import { environment } from '../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { PaymentOption } from '../_services/payment-option.service';



@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  iTokenUrl;
  orderHash: string;
  phoneNumber:string;
  storeId:string;
  paymentGateway;

  form:FormGroup;
  @ViewChild("month",{static: true}) monthElement: ElementRef;
  @ViewChild("year",{static: true}) yearElement: ElementRef;
  @ViewChild("cvc",{static: true}) cvcElement: ElementRef;
  @ViewChild("nameOnCard",{static: true}) nameElement: ElementRef;

  constructor(
    private sanitizer:DomSanitizer,
    private bottomSheetRef: MatBottomSheetRef<PaymentComponent>,
    private bottomSheet: MatBottomSheet,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private orderService: OrderService,
    private router: Router,
    private fb: FormBuilder
  ) {
    console.log(data);

    this.iTokenUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.iTokenUrl}/itoke/ajax-tokenizer.html?tokenizewheninactive=true&css=%0A%0Abody%7Bmargin%3A0px%3B%7D%20.error%7Bcolor%3A%20red%3B%7D%20input%7Bwidth%3A%2096%25%3B%20height%3A%2055px%3B%20border%3A%201px%20solid%20%23e1e1e1%3B%20border-radius%3A%205px%3B%20font-size%3A%2018px%3B%20padding%3A%200%205px%3B%7D%0A`);
    this.orderHash = this.data.orderHash;
    this.phoneNumber = this.data.phoneNumber;
    this.storeId = this.data.storeId;
    this.paymentGateway = this.data.paymentGateway;


    window.addEventListener('message', function (event) {
      var token = JSON.parse(event.data);
      localStorage.setItem('token', token.message)
    });
  }
  ngOnInit() {
    localStorage.setItem('token', null)
    const monthMaxLength = 2;
    const yearMaxLength = 2;
    const cvcMaxLength = 4;
    this.form = this.fb.group({
      month: ["", [Validators.required, Validators.maxLength(monthMaxLength)]],
      year: ["", [Validators.required, Validators.maxLength(yearMaxLength)]],
      cvc: ["", [Validators.required, Validators.maxLength(cvcMaxLength)]],
      nameOnCard: ["", [Validators.required]],
      addtoWallet: [true]
    })

    this.form.get("month").valueChanges.pipe(filter((value: string) => value.length === monthMaxLength))
    .subscribe(() => this.yearElement.nativeElement.focus());

    this.form.get("year").valueChanges.pipe(filter((value: string) => value.length === yearMaxLength))
    .subscribe(() => this.cvcElement.nativeElement.focus());

    this.form.get("cvc").valueChanges.pipe(filter((value: string) => value.length === cvcMaxLength))
    .subscribe(() => this.nameElement.nativeElement.focus());
  }

  submit() {
    if(this.form.valid){
      this.orderService.addNewCard(this.storeId,
          this.phoneNumber,
          this.form.get("nameOnCard").value,
          this.form.get("month").value,
          this.form.get("year").value,
          this.form.get("cvc").value,
          localStorage.getItem('token'),
          this.form.get('addtoWallet').value).subscribe(
        response => {
          let cardDetail = Object.assign(new CardDetails(), response);
          this.bottomSheetRef.dismiss(cardDetail);
        },
        error => {
          this.router.navigate(['order', this.orderHash, 'status', 'failure']);
      })
    }
  }

  dismiss(){
    this.bottomSheetRef.dismiss()
  }

  hasToken(){
    const token = localStorage.getItem('token');
    console.log(token);

    return token && token.length === 16 && this.form.valid;
  }

}
