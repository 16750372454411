import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class LoyaltyPointService {

  endpoint = environment.orderApiBackend;
  token = environment.accessToken;
  // graphmenu = this.endpoint;
  orderEndpoint = `${this.endpoint}/backend/`;
  storeEndpoint = `${this.endpoint}/backend/store/`;
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Token ${this.token}`,
    }),
  };

  constructor(private http: HttpClient) { }

  getTierList(store: any) {
    return this.http.get(this.storeEndpoint +  `${store}/tier/`, this.httpOptions);
  }
  
  pointsUser(payload: any) {
    return this.http.put(this.storeEndpoint + `single_user/points/`, JSON.stringify(payload), this.httpOptions);
  }

  claimUserReward(payload: any) {
    return this.http.post(this.orderEndpoint + `redeem/`, JSON.stringify(payload), this.httpOptions);
  }

  getPointRedeemHistory(storeid: any, hash: any) {
    return this.http.get(this.orderEndpoint + `user/${storeid}/${hash}/point_redeem_history/`, this.httpOptions);
  }
}
