import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-instruction',
  templateUrl: './instruction.component.html',
  styleUrls: ['./instruction.component.scss']
})
export class InstructionComponent implements OnInit {

  @Output() specialRequestChange = new EventEmitter<string>()
  @Input() specialRequest:string;
  constructor() {}

  ngOnInit() {}

  onChange(event:string){
    this.specialRequestChange.emit(event);
  }

}
