import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { CardDetails } from '../models/order';
import {UserActivity} from '../models/user-activity';


@Injectable({
  providedIn: 'root'
})
export class UserActivityService {
  endpoint = environment.orderApiBackend;
  token = environment.accessToken;
  // graphmenu = this.endpoint;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Token ${this.token}`,
    }),
  };

  constructor(private http: HttpClient) { }

  getUserActivity(phoneNumer: any) {
    let params = new HttpParams()
      .set('phone_number', phoneNumer)

    return this.http.get<UserActivity[]>(`${this.endpoint}/backend/v2/sus/get_user_activity/`,
      {params: params, headers:this.httpOptions.headers});
  }
}
