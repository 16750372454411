import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {StripeCardComponent, StripeService} from 'ngx-stripe';
import {StripeCardElementOptions, StripeElementsOptions} from '@stripe/stripe-js';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OrderService} from '../../../order.service';
import {CardDetails} from '../../../models/order';
import {PaymentOption} from '../../../_services/payment-option.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-wallet-add-stripe',
  templateUrl: './wallet-add-stripe.component.html',
  styleUrls: ['./wallet-add-stripe.component.sass']
})
export class WalletAddStripeComponent implements OnInit{

  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#000'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
    appearance: {
      theme: 'flat',
      variables: {
        colorText: '#000',
        colorPrimary: '#000',
        colorTextPlaceholder: '#000',
      }
    }
  };

  stripeTest: FormGroup;
  token: any = null;
  isLoading = false;
  phoneNumber: string;
  storeId: string;

  constructor(private dialogRef: MatDialogRef<WalletAddStripeComponent>,
              private fb: FormBuilder,
              private stripeService: StripeService,
              private orderService: OrderService,
              @Inject(MAT_DIALOG_DATA) public data: any, private _snackBar: MatSnackBar) {
    this.phoneNumber = this.data.phoneNumber;
    this.storeId = this.data.storeId;
  }

  ngOnInit() {
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]]
    });
  }

  createToken(): void {
    this.isLoading  = true;
    const name = this.stripeTest.get('name').value;
    this.stripeService
      .createToken(this.card.element, { name })
      .subscribe((result) => {
        if (result.token) {
          console.log(result)
          this.token = result.token;
          this.orderService.addNewStripeCard(this.storeId, this.phoneNumber, name,
            result.token.card.exp_month.toString(), result.token.card.exp_year.toString(), '123',
            result.token.id, result.token.card.last4, result.token.card.brand,
            result.token.card.address_zip).subscribe(response =>{
            this.isLoading=false;
            this._snackBar.open('Payment method added', '', { duration: 3000, verticalPosition: 'top'});
            const cardDetails = Object.assign(new CardDetails(), response);
            const paymentOption = new PaymentOption(false, true, false, cardDetails, 0)
            this.dialogRef.close(cardDetails);
          })
          // Use the token
          console.log(result.token.id);
        } else if (result.error) {
          // Error creating the token
          console.log(result.error.message);
        }
      });
  }


  dismiss(){
    this.dialogRef.close()
  }
}
